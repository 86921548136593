import React, { FC } from 'react';
import {
  Box,
  SPLITIO_KEY,
  useIdentifyBox,
  T_Billing,
  useSplitIO,
  categoryIds,
  BoxUpgradeStatus,
  useEligibilityOffers,
  useCustomerStore,
  useAddUpdateBox,
  useCoupon,
  useCouponStore,
  BFriday,
  useboxesStore,
  BoxTypes,
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US,
} from '@sky-tv-group/shared';
import { ThumbsUp } from './icon';
import { ICEBoxCard } from './box-line-items/ICEBoxCard';
import { CarouselContainer } from './carousel/CarouselContainer';
import { CustomerGuaranteeTemplate, SkyRewardsTemplate } from './arrow-marketing/NewMarketingCardTemplates';

const slickSettings = {
  autoSlidesToShow: true,
  variableWidth: true,
  infinite: false,
  arrows: false,
  dots: false,
  customClassName: 'custom-carousel slick-slide-px-r-20 slick-slide-px-r-30  -mr-10vw md:mr-0',
  responsiveBlock: [],
};

export enum SourceJourney {
  WEB_ICE = 'WEB-ICE',
  WEB_ACCOUNT = 'WEB-ACCOUNT',
  WEB_DART = 'WEB-DART',
}

type SkyBoxPrimaryProps = {
  box?: Box;
  billing?: T_Billing;
  heading: string;
  mySkySubscribed?: boolean;
  source?: SourceJourney;
  // Opens the manage nickname modal
  manageNicknameButton?: (box: Box) => JSX.Element;
  // The add box button for acquisition
  addBoxButton?: () => JSX.Element;
  // Edit box button for acquisition
  editBoxButton?: (box: Box) => JSX.Element;
  // Opens edit/upgrade box button modal element for upgrade journey
  updateBoxButton?: (box: Box) => JSX.Element | undefined;
};

const SkyBoxPrimary: FC<SkyBoxPrimaryProps> = ({
  box,
  billing,
  heading,
  mySkySubscribed,
  manageNicknameButton,
  updateBoxButton,
  addBoxButton,
  editBoxButton,
  source,
}) => {
  const [showNewSkyBox] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX);
  const [newSkyBoxCSGEnabled] = useSplitIO(SPLITIO_KEY.SKYWEB_NEW_SKY_BOX_CSG);

  const { getBox, getBoxStatus, isBoxEligibleForCSG, doesBoxHaveFreeMySky } = useIdentifyBox();
  const { anyArrowLoyaltyOffers, arrowLoyaltyOffers } = useEligibilityOffers();
  const currentUserArrowLoyaltyOfferDetails = useEligibilityOffers().currentUserArrowLoyaltyOfferDetails();
  const { couponRewardTemplateType } = useCoupon();
  const { coupons } = useCouponStore();
  const boxes = useboxesStore(s => s.boxes);

  // check if Arrow Loyalty Offers coupons is already in store or product(s) is subscribed
  const isArrowLoyaltyOffersSubscribed = (coupons ?? [])?.some(
    c => c?.couponCode === currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode
  );

  // offer banner should always be visible until customer reaches the max limit
  const isBoxReachesMaxLimit =
    (boxes?.filter(b => b?.boxType === BoxTypes.NEW || b.boxType === BoxTypes.UPGRADE)?.length ?? 0) >= 5;

  const showOfferBanner = !(currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
  NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US
    ? isBoxReachesMaxLimit
    : isArrowLoyaltyOffersSubscribed);

  const triggerBoxUpgrade = () => {
    document.getElementById('updateBoxButton')?.click();
  };

  let child;
  if (!box && addBoxButton) {
    child = addBoxButton();
  } else if (box && box.boxType === 'NEW' && editBoxButton) {
    const product = box.products.find(p => p.categoryId === categoryIds.box);
    child = (
      <ICEBoxCard
        key={`${box.id}`}
        box={box}
        product={product}
        status={BoxUpgradeStatus.IN_CART}
        actionButton={editBoxButton({ ...box, serviceCode: product?.sku ?? undefined })}
        isMultiroomBox={true}
      />
    );
  } else if (box) {
    const product = box.occurence ? getBox(box.occurence) : box.products[0];
    if (!product || product.categoryId !== categoryIds.box || !updateBoxButton) return null;
    const serviceStatus = getBoxStatus(box, billing);
    child = (
      <ICEBoxCard
        key={box.id ?? box.occurence?.serialNumber}
        box={box}
        product={product}
        status={serviceStatus}
        manageNicknameButton={manageNicknameButton && manageNicknameButton(box)}
        actionButton={updateBoxButton({ ...box, serviceCode: product.sku })}
        isCSGEligible={
          billing && box.occurence && !!newSkyBoxCSGEnabled && isBoxEligibleForCSG(billing, box.occurence, product)
        }
        hasFreeMySky={billing && doesBoxHaveFreeMySky(billing, box)}
      />
    );
  }

  const customerGuaranteeDesc = (
    <p>
      Satisfaction Guarantee applicable for 30 days after activation of the new Sky Box. Usual monthly subscription and
      additional data usage charges apply. Click here for full{' '}
      <a href="https://sky.co.nz/csg-terms" className="underline" target="_blank" rel="noopener noreferrer">
        T&Cs.
      </a>
    </p>
  );

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        {source === SourceJourney.WEB_ICE ? (
          <h3 className="relative card text-navy flex flex-wrap items-center" data-testid={heading}>
            <span className="sky-h3-black font-black md:sky-h4-black">{heading.split(' ')[0]}</span>
            <span className="sky-h3-reg font-normal md:sky-h4-reg ml-2">{heading.split('Add')}</span>
          </h3>
        ) : (
          <h3
            className="relative card sky-h5-black md:sky-h5-black text-navy flex flex-wrap items-center"
            data-testid={heading}>
            {heading} 
          </h3>
        )}
      </div>
      {mySkySubscribed && (
        <div className="sky-h5 flex pt-8">
          <ThumbsUp />
          <div className="pt-1">
            Great news! With the new Sky Box you'll get the full 1TB of storage while saving $5 per month.
          </div>
        </div>
      )}
      <div className="mt-6">
        <CarouselContainer {...slickSettings}>
          <div className="w-270px mt-6 pb-12">{child}</div>
          {showNewSkyBox && source !== 'WEB-ICE' && anyArrowLoyaltyOffers && showOfferBanner && (
            <>
              <div className="w-270px mt-6 pb-12">
                <SkyRewardsTemplate
                  headline={
                    currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
                    NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US ? (
                      <span>Enjoy the new Sky Box on us!</span>
                    ) : (
                      <span className="absolute" style={{ top: -45 }}>
                        experience
                        <br />
                        New Sky Box{' '}
                      </span>
                    )
                  }
                  description={
                    currentUserArrowLoyaltyOfferDetails?.coupon?.couponCode ===
                    NSB_SKYPOD_MONTHLY_AND_ONEOFF_FEE_ON_US ? (
                      <p>Your search is over! get satellite, streaming and Sky On Demand all in one place.</p>
                    ) : (
                      <p>
                        As a loyal Sky customer, get a Sky Rewards discount of $
                        {currentUserArrowLoyaltyOfferDetails.discount ?? 150} when you upgrade to receive your Sky
                        Services through the new Sky Box, with a one off payment of
                      </p>
                    )
                  }
                  offerPrice={currentUserArrowLoyaltyOfferDetails.discountedPrice ?? 50}
                  rrpPrice={currentUserArrowLoyaltyOfferDetails.originalPrice ?? 200}
                  templateType={couponRewardTemplateType(currentUserArrowLoyaltyOfferDetails.coupon?.couponCode)}
                  couponCode={currentUserArrowLoyaltyOfferDetails.coupon?.couponCode}
                  discountText="You save $200"
                />
              </div>
            </>
          )}
          {!!showNewSkyBox && !!newSkyBoxCSGEnabled && source !== SourceJourney.WEB_ICE && (
            <div className="w-270px mt-6 pb-12">
              <CustomerGuaranteeTemplate
                logoUrl={'https://static.sky.co.nz/sky/skymarketing/sky-30-day.png'}
                headline={'SATISFACTION GUARANTEE'}
                description={customerGuaranteeDesc}
              />
            </div>
          )}
        </CarouselContainer>
      </div>
    </>
  );
};

export { SkyBoxPrimary };
