import React, { useEffect, useState } from 'react';
import useStore from '../../../store';
import { useAuth0 } from '../../../store/authStore/authStore';
import { T_EPG_Channel, T_EPG_Event, T_Product } from '@sky-tv-group/shared';
import { LoggedinModalContent } from './content/LoggedInModalContent';
import { LoggedOutModalContent } from './content/LoggedOutModalContent';

interface Props {
  isSeriesRecord: boolean;
  remoteRecording: (cardId: string) => void;
  closeModal: () => void;
  event: T_EPG_Event;
  channel: T_EPG_Channel;
  missingSubscription?: T_Product;
}

const RecordModalManager = ({
  isSeriesRecord,
  remoteRecording,
  closeModal,
  event: selectedEvent,
  channel: selectedChannel,
  missingSubscription,
}: Props) => {
  const { client, isAuthenticated } = useAuth0();
  const occurrences = useStore(s => s.occurrences)?.filter(o => o.isRemoteRecordCapable);
  const [selectedOccurence, setSelectedOccurence] = useState(
    occurrences && occurrences.length > 0 ? occurrences[0].serialNumber : ''
  );

  const handleLogin = () => {
    // Store event and channel in Session to resume after login redirect
    sessionStorage.setItem('event', JSON.stringify(selectedEvent));
    sessionStorage.setItem('channel', JSON.stringify(selectedChannel));
    sessionStorage.setItem('isSeriesRecord', JSON.stringify(isSeriesRecord));
    if (client) {
      client.loginWithRedirect().catch((err: Error) => {
        console.error(err);
      });
    }
  };

  const handleBoxSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOccurence(e.target.value);
  };

  return (
    <div className="p-2 m-4">
      {isAuthenticated ? (
        <LoggedinModalContent
          selectedChannel={selectedChannel}
          selectedEvent={selectedEvent}
          closeModal={closeModal}
          handleBoxSelect={handleBoxSelect}
          isSeriesRecord={isSeriesRecord}
          occurrences={occurrences}
          remoteRecording={remoteRecording}
          selectedOccurence={selectedOccurence}
          missingSubscription={missingSubscription}
        />
      ) : (
        <LoggedOutModalContent
          selectedChannel={selectedChannel}
          selectedEvent={selectedEvent}
          handleLogin={handleLogin}
        />
      )}
    </div>
  );
};

export default RecordModalManager;
