import create from 'zustand';
import { format } from 'date-fns';

import { navService, getChannels, getEvents, getBoxes, getProfile, getPackagesSubscription } from '../services/index';
import { FETCH_BUFFER, FETCH_RANGE, LIMIT, SINGLE_CHANNEL_BUFFER } from '../constants';
import { NAVIGATION_URL } from '../config';
import { T_EPG_Channel, T_EPG_Event, NavigationLinks, T_Product } from '@sky-tv-group/shared';
import { pixel2Time } from '../utils';

import { T_Occurrence, T_Profile } from '@sky-tv-group/shared';
import { genreFilters } from '../config';

export interface Store {
  profile?: T_Profile;
  occurrences?: T_Occurrence[];
  packages?: T_Product[];
  channels: T_EPG_Channel[];
  channelFilter?: number;
  genre?: typeof genreFilters[number];
  init: number;
  x: number;
  dayOfX: string;
  delta: number;
  latest: number;
  selectedEvent?: T_EPG_Event;
  selectedChannel?: T_EPG_Channel;
  hasError: boolean;
  loading: boolean;
  dragging: boolean;
  navLinks: NavigationLinks;
  fetch: () => void;
  fetchNavLinks: () => void;
  updateSelectedEvent: (event?: T_EPG_Event, channel?: T_EPG_Channel) => void;
  updateChannelFilter: (channel?: T_EPG_Channel) => void;
  updateDrag: (s: any) => void;
  setX: (newX: number) => void;
  updateGenre: (genre: typeof genreFilters[number] | '') => void;
  hasErrorSet: (hasError: boolean) => void;
  setOccurrences: () => void;
  setProfile: () => void;
  setPackages: () => void;
}

const [useStore, store_] = create<Store>((set, get) => ({
  channels: [],
  singleChannelEvents: [],
  navLinks: {
    logoUrl: '/',
    logoSrc: '/',
    TVGuideURL: '/',
    header: [],
    side: [],
    footer: [],
    social: [],
    corporate: [],
  },
  updateSelectedEvent: (selectedEvent?: T_EPG_Event, selectedChannel?: T_EPG_Channel) => {
    set({ selectedEvent, selectedChannel });
  },
  channelFilter: undefined,
  updateChannelFilter: (channelFilter?: T_EPG_Channel) => {
    set({ channelFilter: channelFilter ? Number(channelFilter.number) : undefined });
  },
  genre: undefined,
  updateGenre: genre => {
    set({ genre: genre ? genre : undefined, selectedEvent: undefined });
  },
  // TODO
  updateDrag: ({ delta, first, last, movement, velocity }: any) => {
    if (first) {
      set({ dragging: true });
    }
    if (last) {
      set({ dragging: false });
    }
    if (!first && !last && velocity > 0.5) {
      set({ selectedEvent: undefined });
    }
    set(s => {
      const newX = Math.max(0, s.x - delta[0]);
      return {
        delta: movement[0],
        x: newX,
        dayOfX: format(pixel2Time(newX) + s.init, 'P'),
      };
    });
  },
  setX: (newX: number) => {
    set(s => {
      return {
        x: newX,
        dayOfX: format(pixel2Time(newX) + s.init, 'P'),
        selectedEvent: undefined,
      };
    });
  },
  fetch: async () => {
    const { x, latest, init, channelFilter } = get();
    const cursor = pixel2Time(x) + init;

    const update = async () => {
      set({ loading: true });

      let channelsResponse = await getChannels();
      let buffer = channelFilter ? SINGLE_CHANNEL_BUFFER : FETCH_BUFFER;
      let events = await getEvents(init, cursor + FETCH_RANGE * buffer, LIMIT);

      events = events.map(event => {
        event.startFromInit = event.startAt - init;
        event.endFromInit = event.endAt - init;
        return event;
      });

      set({
        channels: channelsResponse.map(c => ({
          ...c,
          events: events.filter(event => event.channelNumber === Number(c.number)),
        })),
        loading: false,
      });
    };

    if (cursor < latest) return;
    set({ latest: cursor + FETCH_RANGE });
    update();
  },
  fetchNavLinks: async () => {
    const navLnk = await navService.getLinks(NAVIGATION_URL);
    set({ navLinks: navLnk });
  },
  init: Date.now(),
  dayOfX: format(Date.now(), 'P'),
  x: 0,
  delta: 0,
  latest: 0,
  hasError: false,
  loading: false,
  dragging: false,
  hasErrorSet: (hasError: boolean) => {
    set({ hasError });
  },
  setOccurrences: async () => {
    let occurrences = await getBoxes();
    set({ occurrences });
  },
  setProfile: async () => {
    let profile = await getProfile();
    set({ profile });
  },
  setPackages: async () => {
    let packages = await getPackagesSubscription();
    set({ packages });
  },
}));

export default useStore;
export const store = store_;
export const useChannels = () => {
  const channels = useStore(s => s.channels);
  const genre = useStore(s => s.genre);
  return channels.filter(channel => (genre === undefined ? true : channel.genre.includes(genre)));
};
