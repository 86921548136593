import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  T_EPG_Event,
  T_EPG_Channel,
  pageErrorMessage,
  useSplitIO,
  SPLITIO_KEY,
  swrConfig,
  useAnalytics,
} from '@sky-tv-group/shared';
import {
  Maintenance,
  Footer,
  Header,
  ToastContainer,
  ErrorContainer,
  NotificationManager,
  IEBanner,
} from '@sky-tv-group/components';

import { getUser, useAuth0 } from './store/authStore/authStore';
import useStore from './store';
import { LIFERAY_CONNECT_ENDPOINT, DOMAIN } from './config';
import { SWRConfig } from 'swr';
import { routes } from './routes';

let Body = () => {
  const { isAuthenticated } = useAuth0();
  const { updateSelectedEvent, fetch, setOccurrences, setProfile, setPackages, hasErrorSet, hasError } = useStore(
    s => ({
      updateSelectedEvent: s.updateSelectedEvent,
      fetch: s.fetch,
      setOccurrences: s.setOccurrences,
      setProfile: s.setProfile,
      setPackages: s.setPackages,
      hasErrorSet: s.hasErrorSet,
      hasError: s.hasError,
    })
  );

  useEffect(() => {
    let load = async () => {
      try {
        await Promise.all(
          [
            isAuthenticated && setOccurrences(),
            isAuthenticated && setProfile(),
            isAuthenticated && setPackages(),
            fetch(),
          ].filter(Boolean)
        );
      } catch (err) {
        hasErrorSet(true);
      }
    };
    load();
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get Event from session if Any
  useEffect(() => {
    const eventFromSession: T_EPG_Event = JSON.parse(sessionStorage.getItem('event') ?? 'null');
    const channelFromSession: T_EPG_Channel = JSON.parse(sessionStorage.getItem('channel') ?? 'null');
    if (eventFromSession && channelFromSession) {
      updateSelectedEvent(eventFromSession, channelFromSession);
      window.location.href = `#${channelFromSession.number}`;
      sessionStorage.removeItem('event');
      sessionStorage.removeItem('channel');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasError ? (
    <ErrorContainer
      headerText={pageErrorMessage.header}
      lineOne={pageErrorMessage.lineOne}
      lineTwo={pageErrorMessage.lineTwo}
    />
  ) : (
    <SWRConfig value={swrConfig}>
      <ToastContainer />
      <NotificationManager
        notificationsEndpoint={LIFERAY_CONNECT_ENDPOINT}
        isSignedIn={isAuthenticated}
        domain={DOMAIN}
      />
      <Switch>
        {routes.map(({ path, exact, component }) => (
          <Route key={path} exact={exact} path={path} component={component} />
        ))}
        <Route path="*">
          <Redirect to="/notfound" />
        </Route>
      </Switch>
    </SWRConfig>
  );
};

const App = () => {
  const logout = useAuth0(s => s.logout);
  const login = useAuth0(s => s.login);
  const { identify, page } = useAnalytics();
  const { isAuthenticated } = useAuth0();
  const navLinks = useStore(s => s.navLinks);
  const profile = useStore(s => s.profile);
  const fetchNavLinks = useStore(s => s.fetchNavLinks);
  const [isMaintenanceMode] = useSplitIO(SPLITIO_KEY.MAINTENANCE_MODE_TVGUIDE);

  const logoutHandler = async () => {
    logout();
  };

  useEffect(() => {
    fetchNavLinks();
  }, [fetchNavLinks]);

  useEffect(() => {
    if (isAuthenticated) {
      if (profile) {
        // Analytics
        const user = getUser();
        identify(user.sub, profile.email, profile.accountNumber, profile.profileId);
        setTimeout(() => {
          page();
        }, 1000);
      }
    } else {
      page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, profile]);

  useEffect(() => {
    // t&c
    document.querySelectorAll('.footer-placeholder').forEach(e => {
      e.innerHTML = `
          <p class="md:sky-sub py-1">
              The use of this website is protected by copyright and rights reserved. See
              <a href="https://www.sky.co.nz/online-and-mobile-terms" class="underline">here</a> for more details.
          </p>
          `;
    });
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.protocol + '//' + window.location.host} />
      </Helmet>
      <Header
        isSignedIn={isAuthenticated}
        name={profile?.displayName || profile?.firstName}
        SessionClickHandler={isAuthenticated ? logoutHandler : login}
        links={navLinks}
      />
      {isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <>
          <IEBanner>
            <Body />
          </IEBanner>
        </>
      )}
      <Footer links={navLinks} />
    </>
  );
};

export default App;
