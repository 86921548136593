import { AxiosInstance } from 'axios';
import { BroadbandReady, FibreCompanies } from '../types';
import { BroadbandFunnelTypes } from '../segment';
import { isNonNil } from '../helpers/filter';
import { BBPlanIndex } from '../helpers/orderDataBuilders/dataServices/dataServiceBuilder';

enum BroadbandJourney {
  FibreSwap,
  IntactONT,
  NewFibre,
  Unservicable,
}

enum VoiceCategory {
  NoVoice = 'NoVoice',
  Voice = 'SIPONT',
}
export class PrequalService {
  private prequalAgent: AxiosInstance;
  constructor(prequalAgent: AxiosInstance) {
    this.prequalAgent = prequalAgent;
  }

  public getPrequalDetails = async (addressId: string) => {
    const result = await this.prequalAgent.get<Prequal>('/prequal/check?addressId=' + addressId);
    const addressPrequal = new AddressPrequalification(result.status !== 200 ? undefined : result.data);
    return addressPrequal;
  };
}

export class AddressPrequalification {
  public prequal: Prequal;
  public journey: BroadbandJourney;
  constructor(prequal: Prequal | undefined) {
    this.prequal =
      prequal ||
      ({
        addOns: [],
        hardware: [],
        availableProducts: [],
        fibreDetails: [],
        fibreSummary: {
          fibreActive: false,
          fibreAvailable: false,
          fibreInstalled: false,
          fibreEta: undefined,
          fibreInADay: false,
          consentRequired: false,
          consentStatus: 'NotRequired',
          buildingType: '',
          locationId: '',
          rightOfWay: false,
        },
      } as Prequal);
    this.journey = this.getBroadbandJourney();
  }

  public getPrequal() {
    return this.prequal;
  }

  public isFibreAvailable() {
    return this.prequal.fibreSummary?.fibreAvailable ?? false;
  }

  public isConsentRequired() {
    return this.prequal.fibreSummary?.consentRequired ?? false;
  }

  /**
   * Finds the modem that is needed for this address
   */
  public getModemModel() {
    return this.prequal.hardware.find(hw => hw.type === 'Router' && hw.model.endsWith('Router'))?.planDetails[0].key;
  }

  /**
   * Finds mesh device (single)
   */
  public getSingleMeshDeviceModel() {
    return this.prequal.hardware.find(hw => hw.type === 'MESH Satellite' && hw.model === 'Mesh')?.planDetails[0].key;
  }

  /**
   * Gets the ready status of an addres
   */
  public getBroadbandReadyStatus() {
    let summary = this.prequal.fibreSummary;
    if (summary.fibreAvailable && (summary.fibreEta === null || summary.fibreEta === undefined)) {
      return {
        broadbandReadyStatus: BroadbandReady.FibreOK,
        broadbandReadyDate: undefined,
      };
    }
    if (summary.fibreEta !== null && summary.fibreEta !== undefined) {
      return {
        broadbandReadyStatus: BroadbandReady.FibreFuture,
        broadbandReadyDate: summary.fibreEta,
      };
    }
    if (!summary.fibreAvailable) {
      return {
        broadbandReadyStatus: BroadbandReady.FibreNever,
        broadbandReadyDate: undefined,
      };
    }
    return {
      broadbandReadyStatus: BroadbandReady.Unknown,
      broadbandReadyDate: undefined,
    };
  }

  public isFibreSwapJourney = () => this.journey === BroadbandJourney.FibreSwap;
  public isIntactONTJourney = () => this.journey === BroadbandJourney.IntactONT;
  public isNewFibreJourney = () => this.journey === BroadbandJourney.NewFibre;
  public isUnservicableJourney = () => this.journey === BroadbandJourney.Unservicable;

  private getBroadbandJourney() {
    if (
      this.prequal.fibreSummary.fibreAvailable &&
      this.prequal.fibreSummary.fibreInstalled &&
      this.prequal.fibreSummary.fibreActive &&
      !this.prequal.fibreSummary.fibreEta
    ) {
      return BroadbandJourney.FibreSwap;
    }

    if (
      this.prequal.fibreSummary.fibreAvailable &&
      this.prequal.fibreSummary.fibreInstalled &&
      !this.prequal.fibreSummary.fibreActive &&
      !this.prequal.fibreSummary.fibreEta
    ) {
      return BroadbandJourney.IntactONT;
    }

    if (
      this.prequal.fibreSummary.fibreAvailable &&
      !this.prequal.fibreSummary.fibreInstalled &&
      !this.prequal.fibreSummary.fibreActive &&
      !this.prequal.fibreSummary.fibreEta
    ) {
      return BroadbandJourney.NewFibre;
    }

    return BroadbandJourney.Unservicable;
  }

  public getBroadbandFunnelType() {
    if (this.isIntactONTJourney()) return BroadbandFunnelTypes.INTACT_ONT;
    else if (this.isFibreSwapJourney()) return BroadbandFunnelTypes.FIBRE_SWAP;
    else return BroadbandFunnelTypes.NEW_FIBRE;
  }
  private getAvailableProductDetailsByCategory(bbPlanIndex: BBPlanIndex = 0) {
    const availableProducts = this.prequal.availableProducts ?? [];
    if (bbPlanIndex === 0) {
      return availableProducts.flatMap(d => d.details?.filter(x => x.category === 'UFB1000/500'));
    } else if (bbPlanIndex === 1) {
      let result = availableProducts.flatMap(d => d.details?.filter(x => x.category === 'UFB300/100'));

      if (result.length > 0) {
        return result;
      }

      result = availableProducts.flatMap(d => d.details?.filter(x => x.category === 'UFB100/20'));

      if (result.length > 0) {
        return result;
      }

      return availableProducts.flatMap(d => d.details?.filter(x => x.category === 'UFB200/100'));
    } else if (bbPlanIndex === 2) {
      let result = availableProducts.flatMap(d => d.details?.filter(x => x.category === 'UFB50/10'));

      if (result.length > 0) {
        return result;
      }

      return availableProducts.flatMap(d => d.details?.filter(x => x.category === 'UFB50/20'));
    }

    return []
  }

  private getAvailableProductDetails(possibles: PrequalDetail[], voiceAdded: boolean = false) {
    if (!possibles || possibles.length === 0) {
      return null;
    }
    if (voiceAdded) {
      return possibles.find(p => p.voiceCategory === VoiceCategory.Voice) ?? null;
    } else {
      return possibles.find(p => p.voiceCategory === VoiceCategory.NoVoice) ?? null;
    }
  }

  public getProduct(bbPlanIndex: BBPlanIndex, voiceAdded: boolean = false) {
    let details = this.getAvailableProductDetailsByCategory(bbPlanIndex);
    const LFC = this.getLFC();
    details = details.filter(d => d?.supplier?.toLowerCase() === LFC?.toLowerCase());
    const possibles = this.getAvailableProductDetails(details, voiceAdded);
    return possibles?.key || '';
  }

  public getProductKey(bbPlanIndex: BBPlanIndex, voiceAdded: boolean) {
    const LFC = this.getLFC();
    const details = this.getAvailableProductDetailsByCategory(bbPlanIndex).filter(
      d => d?.supplier?.toLowerCase() === LFC?.toLowerCase()
    );
    const possibles = this.getAvailableProductDetails(details, voiceAdded);
    const internetService = possibles?.components?.find(d => d.service === 'Internet');

    if (internetService?.planDetails && internetService.planDetails.length > 0) {
      return internetService.planDetails[0].key;
    }

    return '';
  }

  public getVoiceProductKey(bbPlanIndex: BBPlanIndex) {
    const LFC = this.getLFC();
    const details = this.getAvailableProductDetailsByCategory(bbPlanIndex).filter(
      d => d?.supplier?.toLowerCase() === LFC?.toLowerCase()
    );
    const possibles = this.getAvailableProductDetails(details, true);
    const voiceService = possibles?.components?.find(d => d.service === 'Voice');

    if (voiceService?.planDetails && voiceService.planDetails.length > 0) {
      return voiceService.planDetails[0].key;
    }

    return '';
  }

  public getStaticIPProductKey() {
    let staticIPAddOn = this.prequal.addOns.find((addon: { key: string }) => addon.key === 'static_ip');
    return staticIPAddOn &&
      staticIPAddOn.planDetails &&
      staticIPAddOn.planDetails.length > 0 &&
      staticIPAddOn.planDetails[0].key
      ? staticIPAddOn.planDetails[0]?.key
      : 'Sky_static_ip';
  }

  public isChorusAvailable() {
    return this.prequal.fibreDetails?.chorusNGA?.fibreAvailable;
  }

  public isEnableAvailable() {
    return this.prequal.fibreDetails?.enable?.fibreAvailable;
  }

  public isNorthPowerAvailable() {
    return this.prequal.fibreDetails?.northpower?.fibreAvailable;
  }

  public isUffAvailable() {
    return this.prequal.fibreDetails?.uff?.fibreAvailable;
  }

  public isChorusInstalled() {
    return this.prequal.fibreDetails?.chorusNGA?.fibreInstalled;
  }

  public isEnableInstalled() {
    return this.prequal.fibreDetails?.enable?.fibreInstalled;
  }

  public isNorthPowerInstalled() {
    return this.prequal.fibreDetails?.northpower?.fibreInstalled;
  }

  public isUffInstalled() {
    return this.prequal.fibreDetails?.uff?.fibreInstalled;
  }

  public isChorusActive() {
    return this.prequal.fibreDetails?.chorusNGA?.fibreActive;
  }

  public isEnableActive() {
    return this.prequal.fibreDetails?.enable?.fibreActive;
  }

  public isNorthPowerActive() {
    return this.prequal.fibreDetails?.northpower?.fibreActive;
  }

  public isUffActive() {
    return this.prequal.fibreDetails?.uff?.fibreActive;
  }

  public getLFC() {
    const details = this.getAvailableProductDetailsByCategory(0);
    if (this.isIntactONTJourney()) {
      if (this.isChorusInstalled()) {
        return FibreCompanies.Chorus;
      } else if (this.isEnableInstalled()) {
        return FibreCompanies.Enable;
      } else if (this.isNorthPowerInstalled()) {
        return FibreCompanies.NorthPower;
      } else {
        return FibreCompanies.UFF;
      }
    } else if (this.isFibreSwapJourney()) {
      if (this.isChorusActive()) {
        return FibreCompanies.Chorus;
      } else if (this.isEnableActive()) {
        return FibreCompanies.Enable;
      } else if (this.isNorthPowerActive()) {
        return FibreCompanies.NorthPower;
      } else {
        return FibreCompanies.UFF;
      }
    } else if (this.isNewFibreJourney()) {
      if (this.isChorusAvailable()) {
        return FibreCompanies.Chorus;
      } else if (this.isEnableAvailable()) {
        return FibreCompanies.Enable;
      } else if (this.isNorthPowerAvailable()) {
        return FibreCompanies.NorthPower;
      } else {
        return FibreCompanies.UFF;
      }
    }
    return details && details.length > 0 ? details[0]?.supplier : null;
  }

  public isLfcDoesntTellUsIfConsentRequired() {
    return (
      this.getLFC() === FibreCompanies.UFF ||
      this.getLFC() === FibreCompanies.Enable ||
      this.getLFC() === FibreCompanies.NorthPower
    );
  }

  public isChorus() {
    return this.getLFC() === FibreCompanies.Chorus ?? false;
  }

  public getLocationId() {
    return this.prequal.fibreSummary.locationId;
  }

  // Gets the addons matching the keys in the plan-details
  public getRespectiveAddons(keys: string[]) {
    return this.prequal.addOns.filter(addon =>
      addon.planDetails?.length ? keys.includes(addon.planDetails[0].key) : false
    );
  }

  public getAddonKeys() {
    const addOns = this.prequal.addOns ?? [];
    return addOns.flatMap(x => x.planDetails?.map(y => y.key)).filter(isNonNil);
  }

  public isVoiceAddable() {
    const availableProducts = this.prequal.availableProducts ?? [];
    const bundleAvailableProducts = availableProducts.find(ap => ap.service === 'Bundle');
    return bundleAvailableProducts?.details.some(d => d.category === 'UFB1000/500');
  }

  public consentRequired() {
    /*Vocus has told us 'often LFC doesn’t retrospectively update their record once consent is gained and ont is installed
      In this case we should refer to fibreActive being true then consent status is irrelevant'
      This is why we check isNewFibreJourney() aswell, as this is the journey where consent required is relevant and this
      implictly checks that fibreActive is false */
    return this.isNewFibreJourney() && this.prequal.fibreSummary.consentRequired;
  }
}

export interface Prequal {
  availableProducts: PrequalAvailableProduct[];
  addOns: PrequalAddon[];
  hardware: PrequalHardware[];
  fibreSummary: PrequalFibreSummary;
  fibreDetails: any;
}

export interface PrequalAddon {
  key: string;
  name: string;
  restricted: boolean;
  supports: string[];
  planDetails: PrequalAddonPlanDetail[] | null;
}

export interface PrequalAddonPlanDetail {
  key: string;
  description: string;
  chargeExcludingGst: number;
  chargeIncludingGst: number;
  billFrequency: string;
  unit: string;
  freeSecondsPerCall: number | null;
  destinations: any[];
}

export interface PrequalAvailableProduct {
  service: string;
  details: PrequalDetail[];
}

export interface PrequalDetail {
  key?: string;
  description?: string;
  category?: string;
  supplier?: string;
  categoryGroup?: string | null;
  voiceCategory?: string;
  components?: PrequalComponent[];
  service?: string;
  details?: PrequalDetail[];
}

export interface PrequalComponent {
  service?: string;
  planDetails?: PrequalComponentPlanDetail[];
  productComponentService?: string;
  productComponentPlans?: any[];
}

export interface PrequalComponentPlanDetail {
  key: string;
  description: string;
  chargeExcludingGst: number;
  chargeIncludingGst: number;
  billFrequency: string;
  unit: string;
  dataCap: null;
  dataBanking: boolean | null;
  dataRollover: boolean | null;
  minimumContractTerm: number;
}

export interface PrequalFibreSummary {
  fibreAvailable: boolean;
  fibreInstalled: boolean;
  fibreActive: boolean;
  consentRequired: boolean;
  consentStatus: string;
  rightOfWay: boolean;
  buildingType: string;
  fibreEta?: Date;
  fibreInADay: boolean;
  locationId: string;
}

export interface PrequalHardware {
  make: string;
  model: string;
  type: string;
  planDetails: PrequalHardwarePlanDetail[];
}

export interface PrequalHardwarePlanDetail {
  key: string;
  description?: string;
  chargeExcludingGst: number;
  chargeIncludingGst: number;
  purchaseType: string;
  hardwareProductDescription?: string;
  hardwareProductExtendedDescription?: string;
  hardwareProductMake?: string;
  hardwareProductModel?: string;
  hardwareProductType?: string;
  categoriesSupported?: string[];
  voiceProductVoiceCategories?: any[] | null;
  availableTo?: any[] | null;
}
