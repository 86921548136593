import { buildCableServiceProduct } from './cableServices/cableServiceBuilder';
import { ProductData, CustomerInfo } from './helper';
import { buildVoiceServiceProduct } from './voiceServices/voiceProductBuilder';
import { OrderDetails, Services, T_Coupon, Product, BroadbandProduct } from '../../types';
import { IBroadbandInstallation } from '../../store/broadbandStore';
import { IBroadbandDelivery } from '../../store/broadbandDeliveryStore';
import { AddressPrequalification } from '../../services/prequal';
import { IUpgradableBoxesStore } from '../../store/upgradableBoxesStore';
import { VoiceDataForm } from '../voiceFormFields';
import { broadbandCategories, categoryIds, voiceCategories, VTVMigrationType } from '../../config';
import { AddressService } from '../../services/address';
import { buildDataServiceProduct } from './dataServices/dataServiceBuilder';
import { TrackerService } from '../../services/tracker';
import { IArrowDelivery } from '../../store/arrowDeliveryStore';
import { boxesStoreApi } from '../../store/boxesStore';
const getBroadbandDetails = async (trackingService: TrackerService, houseNumber: string) => {
  let tracking;
  try {
    tracking = await trackingService.getBroadbandDetails(houseNumber);
  } catch {
    tracking = undefined;
  }
  return tracking;
};

export const createRequestBody = async (
  trackerService: TrackerService,
  addressService: AddressService,
  details: IBroadbandInstallation | undefined,
  deliveryDetails: IBroadbandDelivery | undefined,
  orderProductsAddedToCart: ProductData[],
  orderProductsDowngraded: ProductData[],
  customer: CustomerInfo,
  billingServices:
    | Pick<
        Services,
        'CableServiceOccurrence' | 'DataServiceOccurrence' | 'TelephoneServiceOccurrence' | 'OTTServiceOccurrence'
      >
    | undefined,
  coupons: Pick<T_Coupon, 'description' | 'couponCode' | 'custom5'>[] | undefined,
  getPrequalData: (addressId: string) => Promise<AddressPrequalification | undefined>,
  upgradableBoxDetails:
    | Pick<IUpgradableBoxesStore, 'boxInstallRequired' | 'installationDate' | 'installationTime'>
    | undefined,
  voiceDetails: VoiceDataForm | undefined,
  currentlySubscribedBroadbandPackage: ProductData | undefined,
  hasActiveOrPendingMeshBroadbandPackage?: boolean,
  hasActiveStarter: boolean = true,
  arrowDeliveryDetails?: IArrowDelivery | undefined,
  fusionRegion?: string,
  vtvTransfer?: boolean,
  vtvTransferType?: VTVMigrationType
): Promise<OrderDetails> => {
  const request: OrderDetails = {
    products: [] as Product[] | BroadbandProduct[],
  };

  //////////////////////
  //  DTH Section     //
  //////////////////////
  const orderPackages = orderProductsAddedToCart.filter(p => p.categoryId !== categoryIds.box);
  const cableProductDowngrades = orderProductsDowngraded.filter(
    p => !broadbandCategories.includes(p.categoryId) && !voiceCategories.includes(p.categoryId)
  );
  const boxes = boxesStoreApi.getState().boxes;
  // Create cable order details
  const cableServiceProduct = await buildCableServiceProduct(
    orderProductsAddedToCart,
    cableProductDowngrades,
    billingServices,
    upgradableBoxDetails,
    coupons,
    addressService,
    customer,
    fusionRegion,
    boxes,
    arrowDeliveryDetails,
    vtvTransfer,
    vtvTransferType
  );

  if (cableServiceProduct.services.length > 0) {
    request.products.push(cableServiceProduct);
  }

  ///////////////////////////////
  // Broadband + Voice Section //
  ///////////////////////////////

  // Broadband active if theres an order package with a broadband categoryID
  const isSomeBroadbandProductInCart = !!orderPackages?.some(
    orderProduct =>
      orderProduct.categoryId === categoryIds.broadband ||
      orderProduct.categoryId === categoryIds.broadbandServices ||
      orderProduct.categoryId === categoryIds.broadbandOneOffFee ||
      orderProduct.categoryId === categoryIds.broadbandDevices
  );

  // Voice active if theres an order package with a voice categoryID
  const hasVoicePackage = orderPackages.some(p => voiceCategories.includes(p.categoryId));

  // if no Broadband and Voice products, just return the created request
  if (!isSomeBroadbandProductInCart && !hasVoicePackage) return request;

  let broadbandDetails;
  if (currentlySubscribedBroadbandPackage) {
    broadbandDetails = await getBroadbandDetails(trackerService, customer.houseNumber ?? '');
    // if something went wrong with the broadband-details information, prevent the order creation.
    if (!broadbandDetails?.dataProviderUserId) {
      throw new Error('Broadband details missing dataProviderUserId information');
    }
  }

  let dataServiceProduct = await buildDataServiceProduct(
    // CONTINUE HERE WITH BB AND TECH FEE
    trackerService,
    orderPackages,
    coupons,
    details,
    deliveryDetails,
    customer,
    getPrequalData,
    billingServices,
    addressService,
    currentlySubscribedBroadbandPackage,
    hasActiveOrPendingMeshBroadbandPackage,
    broadbandDetails
  );

  if (dataServiceProduct && dataServiceProduct.services.length > 0) {
    request.products.push(dataServiceProduct);
  }

  // if no Voice product, just return the created request
  if (!hasVoicePackage) return request;

  if (voiceDetails && hasVoicePackage) {
    // VOICE SECTION PAYLOAD BUILDER
    let voiceServiceProduct = await buildVoiceServiceProduct(
      orderPackages,
      coupons,
      voiceDetails,
      customer,
      getPrequalData,
      details,
      billingServices?.TelephoneServiceOccurrence,
      currentlySubscribedBroadbandPackage?.sku,
      billingServices,
      !!currentlySubscribedBroadbandPackage,
      broadbandDetails
    );

    if (voiceServiceProduct.services.length > 0) {
      request.products.push(voiceServiceProduct);
    }
  }

  return request;
};
