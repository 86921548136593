import React, { useEffect } from 'react';

import { caret } from '../icon';
import { SocialButtons } from '../social';
import { NavigationLinks } from '@sky-tv-group/shared';

interface Props {
  links: NavigationLinks;
}

export let Footer = ({ links }: Props) => {
  const footerGradient = {
    background: 'linear-gradient(to right, #00013A, #1C9AD6)',
  };

  useEffect(() => {
    // https://shielded.co.nz/
    let shielded = (window as any).ds07o6pcmkorn;
    if (shielded) {
      let frameName = new shielded({
        openElementId: '#shielded-logo',
      });
      frameName.init();
    }
  }, []);

  return (
    <footer className="bg-white pb-16 md:pb-0 border-t border-gray-dark">
      <div className="text-blue-darker block lg:flex">
        <div className="flex-col relative flex-1 text-blue-darker block lg:flex">
          <div
            className="flex relative bottom-1 md:mx-6 lg:mx-0 bottom-1 lg:mb-4 lg:mr-13 lg:ml-0"
            style={{ height: 300 }}>
            <div style={{ minWidth: 300 }} className="hidden lg:block ">
              <img className="h-full" src="https://static.sky.co.nz/sky/logos/footer-logo-new.svg" alt="" />
            </div>
            <div className="flex flex-wrap md:flex-no-wrap flex-1">
              {/* desktop view */}
              <div className="hidden md:flex flex-wrap w-full justify-between">
                <ul data-testid="links" className="flex flex-row justify-between flex-no-wrap w-9/14">
                  {links.footer.map(link => {
                    return (
                      <li key={link.title} className="pt-8 pb-7 md:sky-sub w-1/4">
                        <p className="py-2 font-bold">{link.title}</p>
                        <ul className="py-2">
                          {link.children?.map(subLink => {
                            /* If you want a new tab for a link, set newTab to true in menu.dev.json (ask Joseph for the file). */
                            return subLink.newTab ? (
                              <li key={subLink.title} className="pt-2 font-book">
                                <a href={subLink.link} target="_blank" rel="noopener noreferrer">
                                  {subLink.title}
                                </a>
                              </li>
                            ) : (
                              <li key={subLink.title} className="pt-2 font-book">
                                <a href={subLink.link}>{subLink.title}</a>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
                <div className="flex flex-col pt-8 pr-0 pb-7 w-3/14 flex-grow justify-between items-end">
                  <div data-testid="social-links" className="flex justify-end">
                    <SocialButtons socialIcons={links.social} />
                  </div>
                  <div>
                    <div className="footer-placeholder"></div>
                    <img
                      alt="shielded"
                      id="shielded-logo"
                      height="45"
                      width="45"
                      style={{ cursor: 'pointer', margin: '0px auto', display: 'inherit' }}
                      src="https://shielded.co.nz/img/custom-logo.png"
                    />
                  </div>
                </div>
              </div>
              {/* mobile view */}
              <div className="block md:hidden w-full ">
                {links.footer.map((link, i) => {
                  return (
                    <div key={link.title} className="relative text-xl mx-6">
                      <label
                        className="block py-6 font-bold border-opacity-40 border-b border-gray-dark"
                        htmlFor={'toggle' + i}>
                        {link.title}
                      </label>
                      <input id={'toggle' + i} type="checkbox" className="absolute toggle opacity-0" />
                      <div className="caret w-8 h-8 my-6 absolute top-0 right-0 pointer-events-none">{caret}</div>
                      <ul className="ml-2 p-1">
                        {link.children?.map(subLink => {
                          return subLink.newTab ? (
                            <li key={subLink.title} className="text-base">
                              <a href={subLink.link} target="_blank" rel="noopener noreferrer">
                                {subLink.title}
                              </a>
                            </li>
                          ) : (
                            <li key={subLink.title} className="text-base">
                              <a href={subLink.link}>{subLink.title}</a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
                <div className=" flex justify-around md:justify-end py-5">
                  <SocialButtons socialIcons={links.social} />
                </div>
                <div className="p-3">
                  <img
                    alt="shielded"
                    id="shielded-logo"
                    height="40"
                    width="40"
                    style={{ cursor: 'pointer', margin: '0px auto', display: 'inherit' }}
                    src="https://shielded.co.nz/img/custom-logo.png"
                  />
                </div>
                <div className="pt-0">
                  <div
                    className="border-t py-4 border-gray-dark flex flex-wrap justify-start md:justify-end flex-row
                      md:flex-row-reverse"
                    style={{ ...footerGradient }}
                    id="footerTermsAndCond">
                    <ul className="flex w-full py-3 md:w-auto justify-between">
                      {links.corporate.map(link => {
                        return (
                          <li
                            key={link.title}
                            className="sky-sub font-book px-0 md:px-6 lg:px-10 xl:px-12 text-white mx-4">
                            <a href={link.link}>{link.title}</a>
                          </li>
                        );
                      })}
                    </ul>
                    <div className="pt-4 pb-3 whitespace-no-wrap sky-sub font-book text-white mx-4">
                      © 2020 Sky Network Television Limited
                    </div>
                    <div className="footer-placeholder"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:flex justify-between absolute w-full bottom-0">
            <div
              className="flex justify-between w-full border-t mt-3 pt-3 border-gray-dark"
              style={{ ...footerGradient }}>
              <div className="md:sky-sub whitespace-no-wrap w-full ml-16 flex-1 text-white">
                © 2023 Sky Network Television Limited
              </div>
              <ul className="flex flex-1 flex-row justify-end flex-no-wrap mr-14 pb-2 text-white">
                {links.corporate.map(link => {
                  return (
                    <li key={link.title} className="md:sky-sub font-book ml-4">
                      <a href={link.link}>{link.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
