import React from 'react';
import { format } from 'date-fns';
import { T_EPG_Channel, T_EPG_Event } from '@sky-tv-group/shared';

interface SelectedEventProps {
  selectedEvent: T_EPG_Event;
  selectedChannel: T_EPG_Channel;
}
export const SelectedEvent = ({ selectedEvent, selectedChannel }: SelectedEventProps) => {
  if (!selectedEvent) return <></>;
  return (
    <div className="flex items-center justify-center mx-auto md:w-3/4">
      <img className="m-2 w-24" alt="channel" src={selectedChannel?.logoThumbnail} />
      <div className="m-2">
        <div className="text-xl">
          {selectedEvent.title} {selectedEvent.rating && <span className="text-sm">({selectedEvent.rating})</span>}
        </div>
        <div className="text-base text-gray-darker">
          {format(selectedEvent.startAt, 'EEEE')} {format(selectedEvent.startAt, 'd')}{' '}
          {format(selectedEvent.startAt, 'MMMM')} | {format(selectedEvent.startAt, 'p')} -{' '}
          {format(selectedEvent.endAt, 'p')} | Channel {selectedEvent.channelNumber}
        </div>
      </div>
    </div>
  );
};
