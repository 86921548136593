/**
 * Hook that interacts with analytcs (e.g. Segment)
 */

import { T_Product, T_Coupon, T_Order, T_OrderProduct, T_PPV, NullableDate } from '../types';
import { segment } from '../segment';
import { localStorage } from '../helpers/localStorage/index';

function useAnalytics() {
  const page = () => {
    segment.page();
  };

  const identify = (accountId: string, email: string, accountNumber: string, profileId: string) => {
    // Only trigger Identify call if the stored AccountId (ajs_user_id) is different than the one from Auth0 (accountId from params).
    const storedAccountId = JSON.parse(localStorage.getItem('ajs_user_id') ?? '{}');
    if (!storedAccountId || storedAccountId !== accountId) segment.identify(accountId, email, accountNumber, profileId);
  };

  const identifyAnonymousUser = (email: string, firstName: string, dob: NullableDate) => {
    // Only trigger Identify call on Next button after getting the email in ACQUISITION.
    segment.identifyAnonymousUser(email, firstName, dob);
  };

  const signedIn = (accountNumber: string) => {
    segment.signedIn(accountNumber);
  };

  const resetIdentity = () => {
    segment.resetIdentity();
  };

  const orderCompleted = (
    workOrderNumber: string,
    isUpgrade: boolean,
    order: T_Order,
    coupons?: T_Coupon[],
    packageType?: string,
    accountNumber?: string
  ) => {
    segment.orderCompleted(workOrderNumber, isUpgrade, order, coupons, packageType, accountNumber);
  };

  const vtvMigrationCompleted = () => {
    segment.vtvMigrationCompleted();
  };

  const ppvBooked = (
    orderId: number,
    sku: string,
    name: string,
    price: number,
    total: number,
    affiliation: string,
    funnelType: string,
    brand: string,
    coupon?: string,
    variant?: string
  ) => {
    segment.ppvBooked(orderId, sku, name, price, total, affiliation, funnelType, brand, coupon, variant);
  };

  const ppvAdded = (product: T_PPV) => {
    segment.ppvAdded(product);
  };

  const checkoutStarted = (
    order: T_Order,
    isUpgrade: boolean,
    coupon?: T_Coupon,
    variant?: string,
    isAbandoned?: boolean,
    packageType?: string
  ) => {
    // Handle abandoned cart like a simulation.
    if (isAbandoned) {
      segment.simulationProductsAdded(order, isUpgrade, coupon, variant);
    }
    segment.checkoutStarted(order, isUpgrade, coupon, '', isAbandoned, packageType);
  };

  const productAdded = (product: T_Product, isUpgrade: boolean, quantity = 1, broadbandJourney?: string) => {
    segment.productAdded(product, isUpgrade, quantity);
  };

  const productRemoved = (product: T_Product, isUpgrade: boolean, quantity = 1) => {
    segment.productRemoved(product, isUpgrade, quantity);
  };

  const prequalAddressChecked = (isUpgrade: boolean, addressResult: string) => {
    segment.prequalAddressChecked(isUpgrade, addressResult);
  };

  const returnToAbandonedCart = (
    orderProducts: T_OrderProduct[],
    isUpgrade?: boolean,
    whichCart?: string,
    packageType?: string
  ) => {
    segment.returnToAbandonedCart(orderProducts, isUpgrade ?? false, whichCart ?? '', packageType);
  };

  const ctaClicked = (promotionButtonText: string, promotionCreative: string, promotionName?: string) => {
    segment.promotionClicked(promotionButtonText ?? '', promotionCreative ?? '', promotionName ?? '');
  };

  const trackLabelAndUrl = (navigationCtaText: string, navigationCtaURL: string) => {
    segment.trackLabelAndUrl(navigationCtaText ?? '', navigationCtaURL ?? '');
  };

  const paymentInfoEntered = (
    workOrderNumber: string,
    isUpgrade: boolean,
    paymentMethod: string,
    order?: T_Order,
    coupons?: T_Coupon[],
    packageType?: string
  ) => {
    segment.paymentInfoEntered(workOrderNumber, isUpgrade, paymentMethod, order, coupons, packageType);
  };

  const checkoutStepCompleted = (
    isUpgrade: boolean,
    order: T_Order,
    paymentMethod: string,
    step: number,
    coupons?: T_Coupon[],
    packageType?: string
  ) => {
    segment.checkoutStepCompleted(isUpgrade, order, paymentMethod, step, coupons, packageType);
  };

  const trackQuiz = (
    event: string,
    quizStepHeader: string,
    quizInteractionTypes: string,
    quizInteractionDetails: string | null,
    quizSelections: string | string[],
    quizProgressStatus: string,
    quizRecommendedPackage: string,
    quizRecommendedProducts: string | undefined
  ) => {
    segment.trackQuiz(
      event,
      quizStepHeader,
      quizInteractionTypes,
      quizInteractionDetails,
      quizSelections,
      quizProgressStatus,
      quizRecommendedPackage,
      quizRecommendedProducts
    );
  };

  const faultSwapCTABtnClick = (btnText: string) => {
    segment.faultSwapBtnClick(btnText);
  };

  const trackIssuesWithDevice = (isKeywordFound: boolean, errorDescription: string) => {
    segment.trackIssuesWithDevice(isKeywordFound, errorDescription);
  };

  const faultSwapOrderCompleted = (
    workOrderNumber: string,
    accountNumber: string,
    deviceRequestType: string,
    deviceType: string,
    deviceName: string
  ) => {
    segment.faultSwapOrderCompleted(workOrderNumber, accountNumber, deviceRequestType, deviceType, deviceName);
  };

  return {
    page,
    identify,
    identifyAnonymousUser,
    resetIdentity,
    orderCompleted,
    vtvMigrationCompleted,
    ppvBooked,
    ppvAdded,
    checkoutStarted,
    productAdded,
    productRemoved,
    prequalAddressChecked,
    returnToAbandonedCart,
    ctaClicked,
    trackLabelAndUrl,
    signedIn,
    paymentInfoEntered,
    checkoutStepCompleted,
    trackQuiz,
    faultSwapCTABtnClick,
    trackIssuesWithDevice,
    faultSwapOrderCompleted,
  };
}

export { useAnalytics };
