import React from 'react';

import useStore from '../store';
import { caret } from '@sky-tv-group/components';
import { genreFilters } from '../config';

export const Filter = () => {
  const genre = useStore(s => s.genre);
  const updateGenre = useStore(s => s.updateGenre);
  return (
    <div className="relative my-4 sm:ml-8 lg:ml-32 xl:ml-96">
      <select
        className="w-64 sky-input sky-input--text sky-input--navy appearance-none sky-h5 md:sky-h7"
        value={genre}
        onChange={e => updateGenre(e.target.value as typeof genreFilters[number] | '')}>
        <option value="">All Channels</option>
        {genreFilters.map(f => (
          <option key={f} value={f}>
            {f}
          </option>
        ))}
      </select>
      <div className="w-10 h-12 py-2 mr-1 absolute text-gray-darker top-0 right-0 pointer-events-none">{caret}</div>
    </div>
  );
};
