import { createBrowserHistory } from 'history';
import { useAnalytics } from '@sky-tv-group/shared';

let history = createBrowserHistory();
const { page } = useAnalytics();

history.listen(location => {
  if ('noScroll' in (location.state ?? {})) return;
  setTimeout(() => {
    if (location.hash) {
      let ele = window.document.querySelector(location.hash);
      if (ele) {
        ele.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, 1);

  page();
});

export default history;
