import { format } from 'date-fns';
import React from 'react';

import useStore from '../../store';
import { productSkuIds, SPLITIO_KEY, T_EPG_Channel, T_EPG_Event, useSplitIO } from '@sky-tv-group/shared';
import { RecordButtons } from './RecordButtons';
import { myAccountSWR } from '../../services';

interface EventProps {
  channel: T_EPG_Channel;
  event?: T_EPG_Event;
}

const EventDetail = ({ channel, event }: EventProps) => {
  const [nsbRemoteRecording] = useSplitIO(SPLITIO_KEY.NSB_REMOTE_RECORDING);
  const selectedEvent = useStore(s => s.selectedEvent);
  const occurrences = useStore(s => s.occurrences)?.filter(o => {
    // If it's a NSB don't show remote recording as an option yet
    // This is going to be toggled on using the NSB_REMOTE_RECORDING feature flag
    if (
      // If a NSB
      o.entitlements.find(
        e => e.code === productSkuIds.arrowBox.primary || e.code === productSkuIds.arrowBoxBlack.primary
      ) &&
      // And the NSB remote recording flag is off
      !nsbRemoteRecording
    )
      return false;

    return o.isRemoteRecordCapable;
  });
  const hasRecordableBox = (occurrences?.length ?? 0) > 0;
  const isRecordable = channel.genre.find(g => g === 'Pay-Per-View') === undefined;
  const showEventDetail =
    selectedEvent !== undefined &&
    Number(channel.number) === selectedEvent.channelNumber &&
    // check if event is passed and the same as selectedEvent, else disregard and return true
    (event ? event.id === selectedEvent.id : true);

  // preload data for recording modal
  myAccountSWR.usePackages();
  return selectedEvent !== undefined && showEventDetail ? (
    <div className="relative h-96 md:h-72 lg:h-56">
      <div
        className="absolute bg-blue-light h-96 md:h-72 lg:h-56"
        style={{
          width: '100vw',
          zIndex: 102,
          left: '50%',
          transform: 'translateX(-50%)',
        }}>
        <div className="flex flex-wrap md:flex-no-wrap text-white py-8 px-5 md:py-6 md:px-4 my-0 mx-auto h-full">
          <div className="w-full flex flex-col justify-center md:w-2/4 mx-5 lg:ml-32 flex-grow">
            <div className="sky-h4 lg:sky-h5 font-semibold">
              {selectedEvent.title} {selectedEvent.rating && <span className="text-sm">({selectedEvent.rating})</span>}
            </div>
            <div className="sky-h5 lg:sky-h7">
              {format(selectedEvent.startAt, 'EEEE')} {format(selectedEvent.startAt, 'd')}{' '}
              {format(selectedEvent.startAt, 'MMMM')} | {format(selectedEvent.startAt, 'p')} -{' '}
              {format(selectedEvent.endAt, 'p')} | Channel {selectedEvent.channelNumber}
            </div>
            <p className="sky-sub md:sky-sub my-4">{selectedEvent.synopsis}</p>
          </div>

          <div className="w-full flex flex-col items-center justify-center md:w-2/5 lg:w-2/4 mx-5">
            {isRecordable && hasRecordableBox && (
              <>
                <div className="sky-h4 md:sky-h5 text-center font-semibold">Start Recording</div>
                <RecordButtons channel={channel} event={selectedEvent} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default EventDetail;
