import React from 'react';
import { PPVOccurrenceCableTypes, T_EPG_Channel, T_EPG_Event, T_Occurrence, T_Product } from '@sky-tv-group/shared';
import { Button, caret } from '@sky-tv-group/components';

import { SelectedEvent } from './SelectedEvent';
import { PurchaseModalContent } from './PurchaseModalContent';

interface LoggedInModalProps {
  isSeriesRecord: boolean;
  selectedEvent: T_EPG_Event;
  selectedChannel: T_EPG_Channel;
  occurrences?: T_Occurrence[];
  selectedOccurence: string;
  handleBoxSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  remoteRecording: (cardId: string) => void;
  closeModal: () => void;
  missingSubscription?: T_Product;
}
export const LoggedinModalContent = ({
  isSeriesRecord,
  selectedEvent,
  selectedChannel,
  selectedOccurence,
  occurrences,
  handleBoxSelect,
  remoteRecording,
  closeModal,
  missingSubscription,
}: LoggedInModalProps) => {
  let hasMultipleBoxes = occurrences && occurrences.length > 1;
  if (missingSubscription) {
    return <PurchaseModalContent missingSubscription={missingSubscription} closeModal={closeModal} />;
  }

  return (
    <>
      {hasMultipleBoxes ? (
        <>
          <h4 className="sky-h4 md:sky-h6 m-3 mx-auto mt-3 md:w-3/4">
            Which Sky box would you like to use to record{isSeriesRecord ? ' the series' : ''}:
          </h4>
          <SelectedEvent selectedChannel={selectedChannel} selectedEvent={selectedEvent} />
          <div className="border-t mt-5 mb-2"></div>
          <div className="relative my-3">
            <div className="pb-4 md:pb-0 md:pr-2  w-11/12 mx-auto">
              <label className="block font-bold mb-2">Select a Sky Box</label>
              <div className="relative">
                <select
                  className="w-full sky-input sky-input--text appearance-none"
                  required={true}
                  value={selectedOccurence}
                  onChange={handleBoxSelect}>
                  {occurrences?.map(occ => {
                    return (
                      PPVOccurrenceCableTypes.includes(occ.occurrenceType) && (
                        <option key={occ.serialNumber} className="sky-sub md:sky-sub" value={occ.serialNumber}>
                          {`${occ.nickName ?? 'Unnamed'} (Smartcard No. ${occ.serialNumber})`}
                        </option>
                      )
                    );
                  })}
                </select>
                <div className="w-10 h-12 py-2 mr-1 absolute text-gray-darker top-0 right-0 pointer-events-none">
                  {caret}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SelectedEvent selectedChannel={selectedChannel} selectedEvent={selectedEvent} />
      )}
      <div className="flex flex-no-wrap justify-center md:justify-end w-11/12  mx-auto my-2 md:my-0">
        <div className="relative">
          <div className="pb-4 md:pb-0 ">
            <div className="flex flex-no-wrap relative">
              <Button variant="secondary" className="px-2" onClick={closeModal}>
                Close
              </Button>
              <Button className="px-2" onClick={() => remoteRecording(selectedOccurence!)}>
                Start recording
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
