import React from 'react';
import { T_EPG_Channel, T_EPG_Event } from '@sky-tv-group/shared';
import { Button } from '@sky-tv-group/components';
import { ICE_URL } from '../../../../config';
import { SelectedEvent } from './SelectedEvent';

interface LoggedOutModalProps {
  selectedEvent: T_EPG_Event;
  selectedChannel: T_EPG_Channel;
  handleLogin: () => void;
}
export const LoggedOutModalContent = ({ selectedEvent, selectedChannel, handleLogin }: LoggedOutModalProps) => {
  return (
    <>
      <h4 className="sky-h3 md:sky-h5 mx-auto mt-3 md:w-3/4">
        To record this show, please sign into 'My Account' or Join Sky
      </h4>
      <SelectedEvent selectedChannel={selectedChannel} selectedEvent={selectedEvent} />
      <div className="flex flex-no-wrap justify-center  w-11/12  mx-auto my-2 ">
        <a href={ICE_URL}>
          <Button variant="primary" colorMode="light">
            Join
          </Button>
        </a>
        <Button variant="secondary" colorMode="light" onClick={() => handleLogin()}>
          Sign in
        </Button>
      </div>
    </>
  );
};
