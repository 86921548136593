import { parseQuery, T_EPG_Channel, T_EPG_Event } from '@sky-tv-group/shared';
import { BlockLoader } from '@sky-tv-group/components';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { RecordButtons } from './event/RecordButtons';
import { useSearchResult } from '../services';
import useStore from '../store';
import { TVGuideHeader } from './TVGuideHeader';
interface SearchResultItemProps {
  event: T_EPG_Event;
  channel: T_EPG_Channel;
}
let SearchResultItem = ({ event, channel }: SearchResultItemProps) => {
  return (
    <div data-testid="search-epg-result-item" className="flex flex-wrap border-b py-6">
      <div className="w-full lg:w-7/12 ">
        <p className="sky-h5 md:sky-h5 text-navy font-bold">
          {event.title} | {channel.name} - {`Channel ${event.channelNumber}`}
        </p>
        <div className="sky-text-midnight md:sky-h7 pb-2">
          <span>{format(parseInt(event.start, 10), 'EEEE d LLL')}</span>
          <span> | </span>
          <span>
            {format(parseInt(event.start, 10), 'p')} - {format(parseInt(event.end, 10), 'p')}
          </span>
        </div>
        <p className="sky-text-gray md:sky-h7">{event.synopsis}</p>
      </div>
      <div className="w-full lg:w-5/12 ">
        <RecordButtons channel={channel} event={event} className="justify-end" />
      </div>
    </div>
  );
};

/**
 * only show events which exactly matches the search keyword ,and its relatedEvents
 * @param title
 * @param events
 */
let transformEvent = (title: string, events: T_EPG_Event[], channel?: string) => {
  // as long as event title includes the query
  let re = new RegExp(`\\b${title}\\b`, 'i');
  let evts = events.filter(e => re.test(e.title) && (channel ? Number(channel) === e.channelNumber : true));
  let result = evts.flatMap(e => [e].concat(e.relatedEvents));
  return result.sort((a, b) => {
    return a.startAt - b.startAt;
  });
};

export let Search = () => {
  let location = useLocation();
  let query = parseQuery(location.search);
  let { data: events, isValidating } = useSearchResult(query.query as string);
  const channels = useStore(s => s.channels);
  const updateChannelFilter = useStore(s => s.updateChannelFilter);
  const { channelNumber } = useParams<{ channelNumber: string }>();

  // get events on the current selected day
  const channel = channels.find(ch => ch.number === channelNumber);

  // update channel for search
  useEffect(() => {
    updateChannelFilter(channel);
    return () => {
      updateChannelFilter(); // reset channel filter
    };
  }, [channel ? channel.number : null]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isValidating) {
    return <BlockLoader />;
  }

  let transformedEvents = transformEvent(query.query as string, events ?? [], channelNumber);

  return (
    <>
      <div className="sticky top-4 z-20 bg-white">
        <TVGuideHeader />
      </div>
      <div className="overflow-hidden relative" style={{ zIndex: 9 }}>
        <div className="w-screen-90 flex my-0 mx-auto relative z-10">
          {transformedEvents.length === 0 ? (
            <div className="text-navy mx-auto py-5 sky-h3">No results.</div>
          ) : (
            <div className="w-full">
              {transformedEvents.map(e => {
                let channel = channels.find(c => parseInt(c.number, 10) === e.channelNumber);
                return channel ? <SearchResultItem key={e.id} event={e} channel={channel!} /> : null;
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
