import React, { useState, useMemo } from 'react';

import { SocialButtons } from '../social';
import { closex, menu, TVGuideIcon } from '../icon';
import { SideNav } from './sideNav';
import { SearchBar } from './SearchBar';
import { Cart } from './Cart';
import cx from 'classnames';

import {
  useLockBodyScrollOnValue,
  NavigationLinks,
  Link,
  useMediaQuery,
  useScrollDirection,
  ScrollDirection,
  ICE_URL,
} from '@sky-tv-group/shared';
import User from './User';

const HEADER_HEIGHT = '4rem';
const BROAD_BAND_URL = 'broadband';
const MENU_TO_REMOVE = 'Offers';

interface BtnProps {
  SessionClickHandler: () => void;
  isSignedIn: boolean;
}

let LoginButton = ({
  SessionClickHandler,
  isSignedIn,
  ...rest
}: BtnProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button onClick={() => SessionClickHandler()} {...rest}>
      {isSignedIn ? 'Log out' : 'Log in'}
    </button>
  );
};

interface Props {
  SessionClickHandler?: () => void;
  isSignedIn?: boolean;
  name?: string;
  links: NavigationLinks;
  cartURL?: string;
  cartSummary?: JSX.Element | null;
}

const removeOfferFromMenu = (menus: NavigationLinks, broadBandExists: boolean) => {
  if (broadBandExists) {
    return {
      ...menus,
      header: menus.header.filter((item: Link) => item.title !== MENU_TO_REMOVE),
      side: menus.side.filter((item: Link) => item.title !== MENU_TO_REMOVE),
    };
  }
  return menus;
};

let Header = ({ SessionClickHandler, name, isSignedIn, links, cartURL, cartSummary }: Props) => {
  let currentUrl = window.location.href;
  let mutatedLink: NavigationLinks = currentUrl.toLowerCase().includes(BROAD_BAND_URL)
    ? removeOfferFromMenu(links, true)
    : removeOfferFromMenu(links, false);

  let [showSidebar, showSideBarSet] = useState(false);
  let close = () => showSideBarSet(false);
  const isMobile = useMediaQuery('(max-width: 480px)');
  const iceWebMobile = isMobile && cartURL === ICE_URL;
  const scrollDirection = useScrollDirection(iceWebMobile);
  const headerClassNames = useMemo(
    () =>
      cx(
        'bg-white flex items-center justify-start z-100 px-25px 2xl:px-50px shadow-md sticky top-0 transition-all delay-500 duration-200 ease-out',
        { '-top-5': iceWebMobile && scrollDirection === ScrollDirection.DOWN }
      ),
    [iceWebMobile, scrollDirection]
  );

  useLockBodyScrollOnValue(showSidebar);

  return (
    <>
      {showSidebar && (
        <div className="fixed inset-0 z-1000">
          <div className="absolute inset-0 bg-blue-darker bg-opacity-50" onClick={close}></div>
          <div className="absolute h-full left-0 w-110 md:w-120 max-w-full bg-white">
            <div className="px-3 md:px-6">
              <button className="w-16 h-16 text-blue-darker" onClick={close}>
                {closex}
              </button>
            </div>
            <p className="md:hidden text-2xl md:text-xl text-blue-darker font-bold px-8 pt-4">
              {isSignedIn ? `Hi, ${name}` : 'Welcome'}
            </p>
            <SideNav
              close={close}
              link={{
                children: mutatedLink.side,
              }}
            />

            <div className=" absolute left-0 bottom-0 w-full">
              <div className="flex justify-center py-5">
                <SocialButtons socialIcons={mutatedLink.social} />
              </div>
              {SessionClickHandler && (
                <div className="sm:hidden flex justify-center bg-blue-pure text-white text-center">
                  <LoginButton
                    className="font-bold p-4 text-2xl md:text-xl text-white hover:text-navy"
                    SessionClickHandler={SessionClickHandler}
                    isSignedIn={!!isSignedIn}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* New Navbar */}
      <div className={headerClassNames} style={{ height: HEADER_HEIGHT }}>
        <div className="flex min-w-16 pr-8">
          <div className="flex items-center gap-6 2lg:gap-0 px-0 w-full" style={{ height: HEADER_HEIGHT }}>
            {/* Hide hamburger menu from desktop. */}
            <div className="2lg:hidden">
              <button className="w-10 h-10 relative text-blue-darker top-3px" onClick={() => showSideBarSet(true)}>
                {menu}
              </button>
            </div>
            <div className="w-full h-full flex items-center">
              <a href={mutatedLink.logoUrl}>
                <img src={mutatedLink.logoSrc} className="sm:h-10 h-32px m-auto" alt="Sky logo"></img>
              </a>
            </div>
          </div>
          {/* <div className="border-angle inline-block"></div> */}
        </div>

        <nav id="header-nav" className="new-navbar">
          {mutatedLink.header.map(link => {
            let current = currentUrl.includes(link.link!);
            return (
              <a
                key={link.title}
                href={link.link}
                className={cx('font-bold text-blue-darker  leading-normal', {
                  'md:sky-h6-black': current,
                  'md:sky-h7': !current,
                })}>
                {link.title}
              </a>
            );
          })}
        </nav>

        <div className={`flex items-center gap-6 2lg:gap-4 justify-end 2lg:min-w-64 pl-6 ml-auto`}>
          {iceWebMobile && cartSummary ? (
            cartSummary
          ) : (
            <>
              <a className="flex" href={mutatedLink.TVGuideURL}>
                <TVGuideIcon />
              </a>
              <SearchBar />

              {/* Only show the Cart icon in nav menu when a user is logged out.  */}
              {!isSignedIn && <Cart url={cartURL} />}

              {/* Put My Account on the right side. */}
              {SessionClickHandler && (
                <div className={`border-l-2 sm:flex items-center border-blue-darker pl-4 hidden sm:pt-2`}>
                  {!isSignedIn && <User />}
                  <div className="flex flex-col items-end">
                    {isSignedIn && (
                      <p className="text-lg  text-blue-darker font-bold block whitespace-no-wrap">Hi, {name}</p>
                    )}
                    <LoginButton
                      className="sky-h5-black text-blue-pure text-lg block whitespace-no-wrap"
                      SessionClickHandler={SessionClickHandler}
                      isSignedIn={!!isSignedIn}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { Header, HEADER_HEIGHT };
