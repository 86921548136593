import create from 'zustand';

import { ReduxDevTools } from '../helpers/storeLogger';
import { KonakartService } from '../services/konakart';
import { localStorage } from '../helpers/localStorage';
import { T_Coupon, T_Order, T_OrderRequest } from '../types';
import { CreditCampaignCodes } from '../config';

/**
 * Manages the Order portion of Konakart.
 * Can load, updateOrder and clear the order
 */
interface OrderStore {
  initializedOrderStore: boolean;
  isOrderPending?: boolean;

  order?: T_Order;
  isToggleProduct?:boolean;

  orderRequest?: T_OrderRequest[];

  loadOrderStore: (kkService: KonakartService, customerId: string) => Promise<void>;

  updateOrder: (
    kkService: KonakartService,
    customerId: string,
    products: T_OrderRequest[],
    coupons?: T_Coupon[],
    billingCoupons?:string[],
  ) => Promise<void>;
  updateOrderforAbandoned: (cartItems: any, coupons?: T_Coupon[]) => void;
  clearOrder: (kkService: KonakartService, customerId: string) => Promise<void>;
}
const isAcquisition = ['get.dev.sky.co.nz', 'get.sky.co.nz', 'get.prelive.sky.co.nz', 'localhost:3100'].some(el =>
  window?.location?.host.includes(el)
);
const quizJourney = window?.location?.search.includes('sid') ? true : false;
const localStorageStateName = 'Order Store';
const getOrderRequest = () => {
  try {
    return JSON.parse(localStorage.getItem(localStorageStateName) ?? '[]');
  } catch (error) { }
};

const setOrderRequest = (value: T_OrderRequest[]) => localStorage.setItem(localStorageStateName, JSON.stringify(value));

const [useOrderStore, orderStoreApi] = create<OrderStore>(
  ReduxDevTools(
    (set, get) => ({
      initializedOrderStore: false,
      isOrderPending: true,
      products: [],
      orderRequest: isAcquisition ? [] : getOrderRequest(),
      isToggleProduct:false,
      loadOrderStore: async (kkService, customerId) => {
        if (!customerId) {
          set({ initializedOrderStore: false }, 'customer id not initialized');
          throw new Error('Customer id not initialized');
        }
        let onLoadOrder = await kkService.getOrder(customerId);
        let setOccurenceProducts;
        //WEB-2989 to fix the Primary box render issue in ice-web
        if (getOrderRequest().length > 0 && onLoadOrder.orderProducts.length > 0 && !quizJourney) {
          setOccurenceProducts = getOrderRequest().map((order: { occurrenceNumber: any; productId: any }) => {
            const orderKK = onLoadOrder.orderProducts.find(ordereleKK => ordereleKK.productId === order.productId);
            if (orderKK) {
              return {
                ...orderKK,
                occurrenceNumber: order.occurrenceNumber!,
              };
            }
          });
          onLoadOrder.orderProducts = setOccurenceProducts ? setOccurenceProducts : onLoadOrder.orderProducts;
        }
        set(
          { initializedOrderStore: true, order: onLoadOrder, orderRequest: getOrderRequest() },
          'initialized Order Store'
        );
      },

      /* Update KK Order. */
      updateOrder: async (kkService, customerId, products, coupons,billingCoupons) => {
        let couponCodes = coupons ? coupons.map(cp => cp.couponCode) : [];
        //only for handling bb speed upgrade offer for multiple coupons
        let billingCouponCodes = billingCoupons ? billingCoupons.map(cp => cp) : []; 

        if (products.length === 0 && couponCodes && couponCodes.length > 0) {
          let updatedOrder = await kkService.updateOrder(customerId, []);
          set({ order: updatedOrder, orderRequest: getOrderRequest() }, 'clear order');
          return;
        }

        window && window.document && (window.document.body.style.cursor = 'progress');
        let updatedOrder;
        try {
          updatedOrder = await kkService.updateOrder(customerId, products, couponCodes);
        } catch {
        } finally {
          set({ isOrderPending: false }, 'pending order check');
        }
        window && window.document && (window.document.body.style.cursor = 'default');

        setOrderRequest(products);
  
      // BB speed upgrade discount price $20 for 09075BB and $10 for 09074BB WAA-3900
        let isProduct = false;
        if(billingCouponCodes?.length>1 || get().isToggleProduct){  // only multiple coupons applied we are deducting
            const { BB_FP_BOOST_SPEED_UPGRADE, BB_FP_SPEED_UPGRADE } = CreditCampaignCodes.BB_SPEED_UPGRADE;
            let creditOfferPrice = 0;
            const has09074BB = couponCodes.includes(BB_FP_SPEED_UPGRADE);
            const has09075BB = couponCodes.includes(BB_FP_BOOST_SPEED_UPGRADE);
    
            if (has09074BB || has09075BB) {
              creditOfferPrice = has09074BB ? 10 : has09075BB ? 20 : 0;
              updatedOrder?.orderTotals?.forEach(orderTotal => {
                if (orderTotal.className === 'ot_total_no_otc' || orderTotal.className === 'ot_total') {
                  orderTotal.value = orderTotal.value - creditOfferPrice
                }
              });
              isProduct = true //Dth and bb when product is availabe
            }
          }

        set({ order: updatedOrder, orderRequest: products, initializedOrderStore: true ,isToggleProduct:isProduct}, 'update Order');
      },
      updateOrderforAbandoned: (cartItems, coupons) => {
        let couponCodes = coupons ? coupons.map(cp => cp.couponCode) : [];

        window && window.document && (window.document.body.style.cursor = 'progress');
        let updatedOrder;
        try {
          updatedOrder = cartItems;
        } catch {
        } finally {
          set({ isOrderPending: false }, 'pending order check');
        }
        window && window.document && (window.document.body.style.cursor = 'default');

        //setOrderRequest(products);
        set({ order: updatedOrder, initializedOrderStore: true }, 'update Order');
      },
      /** Clears the cart. And clears the quantity from all the products. Clears in konakart too. */
      clearOrder: async (kkService, customerId) => {
        if (customerId) {
          let updateOrder = get().updateOrder;
          setOrderRequest([]);
          await updateOrder(kkService, customerId, []);
        }
      },
    }),
    'Order Store'
  )
);

export { useOrderStore, orderStoreApi, setOrderRequest };
