import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import useStore from '../../store';
import RecordModalManager from './modal/RecordModalManager';
import { remoteRecord, getProducts } from '../../services/index';

import {
  T_EPG_Channel,
  useToastContainer,
  T_EPG_Event,
  T_Product,
  useSplitIO,
  SPLITIO_KEY,
} from '@sky-tv-group/shared';

import { Loader, Modal } from '@sky-tv-group/components';
import { RecordButton } from './RecordBtn';
import { useAuth0 } from '../../store/authStore/authStore';

interface RecordButtonsProps {
  channel: T_EPG_Channel;
  event: T_EPG_Event;
  className?: string;
}
export let RecordButtons = ({ channel, event: selectedEvent, className = 'justify-center' }: RecordButtonsProps) => {
  let overrideProductInfoFromKK = useSplitIO(SPLITIO_KEY.SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART);

  let [modalIsOpen, setIsOpen] = useState(false);
  let [seriesRecord, setSeriesRecord] = useState(false);
  let [missingSubscription, setMissingSubscription] = useState<T_Product | undefined>(undefined);
  let [loading, setLoading] = useState(false);
  let { isAuthenticated } = useAuth0();
  const { addToast, addSuccessToast } = useToastContainer();
  const updateSelectedEvent = useStore(s => s.updateSelectedEvent);
  const packages = useStore(s => s.packages);

  function openModal(isSeries: boolean = false) {
    setSeriesRecord(isSeries);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  // If there's an SeriesRecord flag from session, open modal and clean flag
  useEffect(() => {
    const isSeriesRecordFromSession = sessionStorage.getItem('isSeriesRecord');
    if (selectedEvent && isSeriesRecordFromSession) {
      if (Number(channel.number) === selectedEvent.channelNumber) {
        openModal(isSeriesRecordFromSession === 'true');
        sessionStorage.removeItem('isSeriesRecord');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  // If the user is subscribed or not to the package
  useEffect(() => {
    let findMissingSubscription = async () => {
      let products = await getProducts(overrideProductInfoFromKK ? true : false);
      setMissingSubscription(products.find((prod: { sku: string }) => prod.sku === channel.sku));
    };

    // wait for packages to get fetched (billing api takes ~2 seconds to load)
    if (!packages && isAuthenticated) {
      setLoading(true);
    } else {
      setLoading(false);
      let packageFound = packages?.find(pkg => pkg.sku === channel.sku);
      if (!packageFound) {
        findMissingSubscription();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  const remoteRecording = (cardID: string) => {
    if (selectedEvent) {
      setLoading(true);
      remoteRecord({
        eventID: selectedEvent.id,
        smartCardID: cardID,
        ...(seriesRecord && selectedEvent.seriesId && { seriesId: Number(selectedEvent.seriesId) }),
      })
        .then(() => {
          addSuccessToast({
            message: `${selectedEvent.title} on ${channel?.name} (Channel ${channel?.number}) at ${format(
              selectedEvent?.startAt,
              'p'
            )}
                      on ${format(selectedEvent.startAt, 'EEEE')} ${format(selectedEvent.startAt, 'd')}${' '} ${format(
              selectedEvent.startAt,
              'MMMM'
            )}
              has been scheduled to record on your SKY box`,
          });
        })
        .catch((err: Error) => {
          addToast({
            title: 'Alert',
            type: 'error',
            message: 'Something went wrong... please try again.',
          });
        })
        .finally(() => {
          setLoading(false);
          closeModal();
          updateSelectedEvent(undefined);
        });
    }
  };

  const modalProps = {
    isSeriesRecord: seriesRecord,
    loading,
    remoteRecording,
    closeModal,
    event: selectedEvent,
    channel,
    missingSubscription,
  };
  return (
    <div className={`flex items-center mt-5 ${className}`}>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={() => closeModal()}>
          {loading ? <Loader /> : <RecordModalManager {...modalProps} />}
        </Modal>
      )}
      {selectedEvent?.id && <RecordButton variant="Episode" onClick={() => openModal(false)} />}
      {selectedEvent?.seriesId && <RecordButton variant="Series" onClick={() => openModal(true)} />}
    </div>
  );
};
