import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import AutoSuggest, { ChangeEvent } from 'react-autosuggest';
import cx from 'classnames';
import debounce from 'lodash.debounce';
import { Field, Form } from 'react-jeff';
import { checkValidAddress, AddressService, AddressLookup, NullableAddressLookup } from '@sky-tv-group/shared';

interface IValidatedAddressSuggest {
  addressField: Field<NullableAddressLookup, string>;
  formValidation: Form<string>;
  placeholder?: string;
  id: string;
  className: string;
  disabled?: boolean;
  addressType: string;
  addressService: AddressService;
  excludeExpression?: string;
}

/**
 * Common address field that uses the address service + react-autosuggest + react-jeff for address auto suggest with validation
 */
const ValidatedAddressSuggest: React.FunctionComponent<IValidatedAddressSuggest> = ({
  addressField,
  formValidation,
  placeholder,
  id,
  className,
  disabled,
  addressType,
  addressService,
  excludeExpression,
}) => {
  // state
  const [searchResults, setSearchResults] = useState<AddressLookup[]>([]);
  const [selectedValidAddress, setSelectedValidAddress] = useState<NullableAddressLookup>();
  // error validation check
  const errorPresent = addressField.errors.length > 0;

  // classnames
  const inputClassName = cx(
    'sky-input sky-input--text sky-h7-reg',
    { 'bg-gray-light': disabled },
    { 'sky-input--error': errorPresent && !disabled },
    className
  );
  const errorClassName = cx(className, { 'text-error': errorPresent && !disabled });

  // methods used by autosuggest

  const getSuggestions = async (value: string): Promise<AddressLookup[]> => {
    const suggestions = await addressService.getAddress(value, addressType);
    // only take the top 5 results from the address data
    let filter = (value: AddressLookup, index: number, array: AddressLookup[]) => true;
    if (excludeExpression) {
      let excludeRegExp = new RegExp(excludeExpression, 'i');
      filter = (value, index, array) => !excludeRegExp.test(value.label);
    }
    return suggestions.filter(filter).slice(0, 5);
  };

  const getSuggestionValue = (address: AddressLookup) => {
    return address.label;
  };

  const renderSuggestions = (address: AddressLookup) => <div id={address.id}>{address.label}</div>;

  // update addressField validations
  // Don't execute on down and up arrow keys.
  const onChange = (_event: FormEvent<any>, params: ChangeEvent) => {
    if (
      params.method === 'click' ||
      params.method === 'enter' ||
      params.method === 'type' ||
      params.method === 'escape'
    ) {
      _event.preventDefault();
      const matchingAddress = searchResults.find(i => i.label === params.newValue);
      addressField.props.onChange({ label: params.newValue, id: matchingAddress?.id ?? '' });
    }
  };

  const onSuggestionsFetchRequested = useCallback(
    debounce(async ({ value }: { value: string }) => {
      const data = await getSuggestions(value);
      setSearchResults(data);
    }, 250),
    []
  );

  const onSuggestionSelected = (event: FormEvent<any>, test: any) => {
    // since its from the suggestions, its valid
    setSelectedValidAddress({ label: test.suggestion.label, id: test.suggestion.id });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSearchResults([]);
  };

  // if user has entered valid address before and then they change to another field but don't select a valid address
  // reset back to the valid one they did first time
  const onBlur = () => {
    if (selectedValidAddress && !checkValidAddress(addressField.value)) {
      addressField.setValue(selectedValidAddress);
    }
  };

  // ensure that we keep track of valid address selected
  useEffect(() => {
    // if one of field empty then its invalid
    // we set address field to null, if we want to prevent the always correct selected value
    if (addressField.value === null) {
      setSelectedValidAddress(addressField.value);
    } else if (checkValidAddress(addressField.value) && !selectedValidAddress) {
      setSelectedValidAddress(addressField.value);
    }
  }, [addressField.value, selectedValidAddress]);

  // Autosuggest will pass through all these props to the input.
  const autoSuggestProps = {
    placeholder: placeholder ?? '',
    value: addressField.value ? addressField.value.label : '',
    onChange: onChange,
    id: id,
    className: inputClassName,
    required: addressField.required,
    disabled: disabled,
    onBlur: onBlur,
  };

  return (
    <>
      <AutoSuggest
        suggestions={searchResults}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestions}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={autoSuggestProps}
      />
      {errorPresent && !disabled && <p className={errorClassName}>{addressField.errors[0]}</p>}
    </>
  );
};

export { ValidatedAddressSuggest };
