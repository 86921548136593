import React, { useState, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { closex, search } from '@sky-tv-group/components';
import { useSearchResult } from '../services';
import { DropDown } from './search/DropDown';
import { useHistory } from 'react-router-dom';
import useStore from '../store';

export const SearchInput = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [query, setQuery] = useState('');
  const history = useHistory();
  const channelFilter = useStore(s => s.channelFilter);

  let dropdown;
  let icon;
  let { data: events } = useSearchResult(query);

  const debouncedSearch = useCallback(
    debounce((newQuery: string) => setQuery(newQuery), 250),
    []
  );

  const closeDropdown = useCallback(() => setQuery(''), []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearchTerm(value);
    // cleaning up the string for regex input
    const term = value.trim().replace(/[\]\[|&;$%@"<>()+,]/g, ''); // eslint-disable-line no-useless-escape
    if (term !== '' && term.length > 1) {
      debouncedSearch(term);
    } else {
      debouncedSearch(''); // this will close the dropdown and no api call sent
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setQuery('');
      if (channelFilter) {
        const channel = String(channelFilter).padStart(3, '0');
        history.push(`/channel/${channel}/search?query=${encodeURIComponent(searchTerm)}`);
      } else {
        history.push(`/search?query=${encodeURIComponent(searchTerm)}`);
      }
    }
  };

  if (!events || events?.length === 0) {
    dropdown = null;
  } else {
    const re = new RegExp(`\\b${query}\\b`, 'i'); // checks if a word is in the title, ignore case
    const list = events.filter(({ title, channelNumber }) => {
      return re.test(title) && (channelFilter ? channelFilter === channelNumber : true);
    });
    dropdown = <DropDown list={list} close={closeDropdown} query={query} />;
  }

  if (searchTerm !== '') {
    icon = (
      <button
        className="absolute top-0 right-0 w-10 h-12 py-1 mr-1 text-navy"
        onClick={() => {
          setSearchTerm('');
          setQuery('');
        }}>
        {closex}
      </button>
    );
  } else {
    icon = <button className="absolute top-0 right-0 w-6 h-12 py-1 mr-3 text-navy">{search}</button>;
  }

  return (
    <div className="relative lg:ml-4 flex-auto text-gray-darker">
      <input
        data-testid="search-epg"
        className="sky-input sky-input--text sky-input--navy appearance-none my-4 sky-h5 md:sky-h7 w-full"
        type="text"
        placeholder="Search programme"
        value={searchTerm}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {icon}
      {dropdown}
    </div>
  );
};
