import React from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { Loader } from '@sky-tv-group/components';
import useStore from '../store';
import { T_EPG_Channel, T_EPG_Event } from '@sky-tv-group/shared';
import EventDetail from './event/EventDetail';
import { useEffect } from 'react';
import { TVGuideHeader } from './TVGuideHeader';
import { TVGuideControl } from './TVGuideControl';

interface Props {
  selected: boolean;
  channel: T_EPG_Channel;
  event: T_EPG_Event;
  onClick: (e: T_EPG_Event) => void;
}

const EventBlock = ({ channel, event, selected, onClick }: Props) => {
  const current = new Date().getTime();
  const live = current >= event.startAt && current <= event.endAt;

  return (
    <div className="mb-1 mt-1">
      <div className="w-screen-90 mx-auto cursor-pointer">
        <div className="lg:w-13/14 mx-auto flex flex-wrap border border-gray-light">
          <div className={`w-2 ${selected ? 'sky-morpheus-dan-gradient' : ''}`}></div>
          <div className="flex flex-1 flex-wrap  py-5 px-5 md:px-8" onClick={() => onClick(event)}>
            <p className="sky-h5 font-bold md:font-normal w-3/10 md:w-2/14">{format(event.startAt, 'p')}</p>
            <p className="sky-h5 font-bold">{event.title}</p>
            {live && <p className="text-sm ml-4 md:ml-10 text-white bg-green rounded-round px-3 pt-1">On Air</p>}
          </div>
        </div>
      </div>
      <EventDetail channel={channel} event={event} />
    </div>
  );
};

const ChannelEvents = () => {
  const { channelNumber } = useParams<{ channelNumber: string }>();
  const { dayOfX, loading, channels, selectedEvent } = useStore(s => ({
    dayOfX: s.dayOfX, // current selected day in the weekslots
    loading: s.loading,
    channels: s.channels,
    selectedEvent: s.selectedEvent,
  }));
  const updateSelectedEvent = useStore(s => s.updateSelectedEvent);
  const updateChannelFilter = useStore(s => s.updateChannelFilter);

  // get events on the current selected day
  const channel = channels.find(ch => ch.number === channelNumber);
  const events = channel?.events.filter(ch => format(ch.startAt, 'P') === dayOfX);
  const hasEvents = !loading && events && events.length > 0;

  // update channel for search
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    updateChannelFilter(channel);
    return () => {
      updateChannelFilter(); // reset channel filter
    };
  }, [channel ? channel.number : null]); // eslint-disable-line react-hooks/exhaustive-deps

  const onEventClick = (event: T_EPG_Event) => {
    updateSelectedEvent(selectedEvent && selectedEvent.id === event.id ? undefined : event, channel);
  };

  return (
    <>
      <div className="sticky top-4 z-20 bg-white">
        <TVGuideHeader />
        <TVGuideControl />
      </div>
      <div className="overflow-hidden relative" style={{ zIndex: 9 }}>
        <div className="w-screen-90 flex my-0 mx-auto relative z-10">
          <div className={`w-full mb-20 ${loading ? 'min-h-screen' : ''}`}>
            {loading && (
              <div className="absolute w-full h-screen">
                <Loader bgOpacity={false} />
              </div>
            )}
            {hasEvents &&
              channel &&
              events?.map(event => (
                <EventBlock
                  key={event.id}
                  channel={channel}
                  event={event}
                  selected={event.id === selectedEvent?.id}
                  onClick={onEventClick}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelEvents;
