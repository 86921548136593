import { Campaign } from '@sky-tv-group/graphql';
import { parseISO } from 'date-fns';
import uniq from 'lodash.uniq';
import {
  categoryIds,
  digiRentalCodes,
  iglooCodes,
  productSkuIds,
  STAFF_SERVICE_CODES,
  vodafoneCodes,
  voiceCategories,
  summerSetCampaignCode,
  priceHoldCampaignCodes,
  catalogueTypeSKU,
} from '../config';
import { isNonNil } from '../helpers/filter';
import {
  BillingCampaign,
  CouponTypes,
  ServiceDetail,
  ServiceOccurrence,
  Services,
  ServiceStatus,
  T_Billing,
  T_Occurrence,
  T_OrderProduct,
  T_Product,
  UserGroupType,
} from '../types';

// Product categories that will have more than 1 item in cart.
const multiQuantityCategories: number[] = [
  categoryIds.box,
  categoryIds.multiroom,
  categoryIds.monthlyFee,
  categoryIds.recording,
];

/**
 * Boxes will have entitlements and we match what type of box it is by the product sku's and see if all of the product sku's are present.
 * If so that means it matches the same type of box as the product and the quantity exists.
 * If nothing found that matches the quantity of that product will be 0
 * also returns occurrenceNumbers for calculating campaign prices for boxes and multiroom.
 * @param boxes
 * @param product
 */
export let getBoxQuantityAndOccurrences = (boxes: T_Occurrence[], product: T_Product) => {
  let skus = product.sku.split(',');
  let matchedBoxes = boxes.filter(box => {
    let entCodes = box.entitlements.map(e => e.code);
    if (entCodes.includes(productSkuIds.arrowBoxBlack.primary)) {
      entCodes = entCodes.filter(code => code !== productSkuIds.arrowBoxBlack.primary);
      entCodes = entCodes.concat(productSkuIds.arrowBox.primary);
    }
    return entCodes.filter(code => skus.includes(code)).length === skus.length;
  });

  return {
    quantity: product.sku.split(',')?.length > 1 ? 0 : matchedBoxes.length,
    occurrenceNumbers: matchedBoxes.map(b => b.occurrenceNumber),
  };
};

export let getBoxFromOccurrences = (boxes: T_Occurrence[], product: T_Product) => {
  let matchBoxes = boxes
    .map(box => {
      let skus = box.entitlements.map(e => e.code);
      // swap arrow box black with arrow box white
      if (skus.includes(productSkuIds.arrowBoxBlack.primary)) {
        skus = skus.filter(code => code !== productSkuIds.arrowBoxBlack.primary);
        skus = skus.concat(productSkuIds.arrowBox.primary);
      }

      if (skus.includes(product.sku) && !(product.sku.split(',')?.length > 1)) {
        return { quantity: 1, occurrenceNumber: box.occurrenceNumber };
      }

      return null;
    })
    .filter(isNonNil);

  return matchBoxes;
};

export let getProductForOccurence = (box: T_Occurrence, products: T_OrderProduct[], isPrimary: boolean) => {
  if (!box) return undefined;
  let entCodes = box.entitlements.map(e => e.code);
  if (entCodes.includes(productSkuIds.arrowBoxBlack.primary)) {
    entCodes = entCodes.filter(code => code !== productSkuIds.arrowBoxBlack.primary);
    entCodes = entCodes.concat(productSkuIds.arrowBox.primary);
  }
  let matchedProduct = products
    .filter(p => (isPrimary ? p.categoryId === categoryIds.box : p.categoryId === categoryIds.multiroom))
    .find(product => {
      let skus = product.product.sku.split(',');
      return skus.every(sku => entCodes.includes(sku));
    });

  return matchedProduct;
};

export let getActiveCampaigns = (
  campaigns: BillingCampaign[],
  filterBy: (c: BillingCampaign) => boolean = () => true
) => {
  return campaigns.filter(c => {
    let endTime = parseISO(c.endDate);
    return c.active && endTime.getTime() >= new Date().getTime() && filterBy(c);
  });
};

/**
 * get price info from campaign.
 * @param product
 * @param activeCampaigns
 * @param occurrenceNumber
 */
export let getPriceFromCampaign = (
  product: T_Product,
  activeCampaigns: BillingCampaign[],
  occurrenceNumber: string[],
  couponType: CouponTypes
) => {
  let priceFromCampaign: number | undefined = undefined;
  let servicesFromCampaignMatchingOccurrences: ServiceDetail[];

  switch (couponType) {
    case CouponTypes.Cable:
      servicesFromCampaignMatchingOccurrences = activeCampaigns.flatMap(active =>
        active.containedServices.CableServiceOccurrence?.filter(c =>
          occurrenceNumber.includes(c.occurrenceNumber.toString())
        ).flatMap(c => c.serviceDetails)
      );
      break;
    case CouponTypes.Broadband:
      servicesFromCampaignMatchingOccurrences = activeCampaigns.flatMap(active =>
        active.containedServices.DataServiceOccurrence?.filter(c =>
          occurrenceNumber.includes(c.occurrenceNumber.toString())
        ).flatMap(c => c.serviceDetails)
      );
      break;
    case CouponTypes.Voice:
      servicesFromCampaignMatchingOccurrences = activeCampaigns.flatMap(active =>
        active.containedServices.TelephoneServiceOccurrence?.filter(c =>
          occurrenceNumber.includes(c.occurrenceNumber.toString())
        ).flatMap(c => c.serviceDetails)
      );
      break;
    default:
      servicesFromCampaignMatchingOccurrences = activeCampaigns.flatMap(active =>
        active.containedServices.CableServiceOccurrence?.filter(c =>
          occurrenceNumber.includes(c.occurrenceNumber.toString())
        ).flatMap(c => c.serviceDetails)
      );
      break;
  }

  let servicesFromCampaignMatchingSKU = servicesFromCampaignMatchingOccurrences.filter(s => {
    // the regex here matches all skus that starts with '11' except for those that
    // ends with 'BB' or 'TP' and strips the '11'.
    // this is to support matching VTV products w/o wrongly stripping '11' on BB and TP products
    let servicdeCode = (!s?.serviceCode.endsWith('BB') && !s?.serviceCode.endsWith('TP')
      ? s?.serviceCode.replace(/^(11)/, '')
      : s?.serviceCode
    ).replace('P', '');
    return product.sku.split(',').includes(servicdeCode);
  });
  if (servicesFromCampaignMatchingSKU.length > 0) {
    priceFromCampaign = servicesFromCampaignMatchingSKU.reduce(
      (prev, cur) => prev + parseFloat(cur.rate.afterTax ?? '0') / 100,
      0
    );
  }

  return priceFromCampaign;
};

export let getBoxDetailsFromBilling = (
  product: T_Product,
  occurrenceNumber: string[],
  services: Services | undefined,
  activeCampaigns: BillingCampaign[]
) => {
  let boxPrice: number | undefined = undefined;

  // Service details from cable service
  const servicesFromMatchingOccurrences = services?.CableServiceOccurrence?.filter(c =>
    occurrenceNumber.includes(c.occurrenceNumber.toString())
  ).flatMap(c => c.serviceDetails);

  // Service details from campaign matching occurrence
  const servicesFromCampaignMatchingOccurrences = activeCampaigns.flatMap(active =>
    active.containedServices.CableServiceOccurrence?.filter(c =>
      occurrenceNumber.includes(c.occurrenceNumber.toString())
    ).flatMap(c => c.serviceDetails)
  );

  const servicesFromMatchingSKU = product.sku
    .split(',')
    .map(
      sku =>
        servicesFromCampaignMatchingOccurrences.find(s => s.serviceCode === sku) ??
        servicesFromMatchingOccurrences?.find(s => s.serviceCode === sku)
    )
    .filter(Boolean);

  if (servicesFromMatchingSKU && servicesFromMatchingSKU.length > 0) {
    boxPrice = servicesFromMatchingSKU.reduce((prev, cur) => prev + parseFloat(cur?.rate.afterTax ?? '0') / 100, 0);
  }

  const inPromotion =
    servicesFromCampaignMatchingOccurrences.filter(s => product.sku.split(',').includes(s?.serviceCode)).length > 0;

  return { boxPrice, inPromotion };
};

export let getDiscrepancies = (monthlyCharge: number, totalPrices: number, totalDiscount: number) => {
  monthlyCharge = Math.round(monthlyCharge * 100);
  totalPrices = Math.round(totalPrices * 100);
  totalDiscount = Math.round(totalDiscount * 100);

  let discrepancies = (monthlyCharge - (totalPrices + totalDiscount)) / 100;
  return discrepancies;
};

/**
 * Logic to update quantity for staff accounts to have positive values instead of 0s to show that its something they have.
 * Staff account do have free quantity which does not map to quantity. Need to resolve this via web.
 * Remove if Query2 handles free quantity
 * @param isStaffAccount
 * @param billing
 */
export const updateStaffAccountQuantity = (
  isStaffAccount: boolean,
  cableServiceOccurrences: ServiceOccurrence[]
): ServiceOccurrence[] => {
  if (!isStaffAccount) {
    return cableServiceOccurrences;
  }
  // map through the cable service occurrences
  cableServiceOccurrences = cableServiceOccurrences.map(occurrence => {
    // only care about active occurrences
    if (occurrence.status !== 1) {
      return occurrence;
    }
    // for those active occurrences iterate through the services
    occurrence.serviceDetails = occurrence.serviceDetails.map(service => {
      // update the ones staff get for free and ensure that the quantity of those were 0
      if (STAFF_SERVICE_CODES.includes(service.serviceCode) && service.quantity === 0) {
        service.quantity++;
        service.rate.afterTax = '0';
      }
      return service;
    });
    return occurrence;
  });
  return cableServiceOccurrences;
};

export const updateProductsForStaffAccount = (products: T_Product[]) => {
  return products.map(product => {
    if (STAFF_SERVICE_CODES.includes(product.sku)) {
      product.priceIncTax = 0;
    }
    return product;
  });
};

/**
 * Loads the cable product data from billing and sets konakart products with correct billing data.
 * @param products
 * @param boxes
 * @param billing
 */
export const resolveCablePackages = (
  products: T_Product[] | undefined,
  occurrences: T_Occurrence[] | undefined,
  billing: T_Billing | undefined
) => {
  let boxes = (occurrences ?? [])
    .filter(b => b.occurrenceType !== 'B/B')
    .sort((a, b) => parseInt(a.occurrenceNumber, 10) - parseInt(b.occurrenceNumber, 10));
  // checks for active status and endDate of campaign
  let cableCampaigns = getActiveCampaigns(
    billing?.campaigns ?? [],
    c => c.containedServices.CableServiceOccurrence !== null
  );

  // the first occurrence which will be the default box
  let billingItems = billing?.services.CableServiceOccurrence?.find(serviceDetail => {
    // Get arrow box instance to check if there is a pending WO for a arrow box update
    const arrowBox = serviceDetail.serviceDetails.find(d => d.serviceCode === productSkuIds.arrowBox.primary);
    // check if the status of the arrow box is pending install and the service is a pending WO
    if (
      arrowBox?.serviceStatus === ServiceStatus.PendingInstall &&
      serviceDetail.status === ServiceStatus.DataPendingOrder
    ) {
      return true;
    }
    return serviceDetail.status === ServiceStatus.Active;
  })?.serviceDetails;
  let hasPendingBox = billing?.services.CableServiceOccurrence?.some(o => o.status === 3);
  // if we have the data, and then we find there are no active occurrences found - error state
  let occurrenceNotFound = billing && !billingItems ? true : false;
  // if at least one billing item is 205 and is active, then customer is considered in broadcast tier (digital rental)
  let isBroadcastTier = billingItems?.some(
    item => item.serviceCode === productSkuIds.broadcastTier.primary && item.serviceStatus === ServiceStatus.Active
  );
  let isStarterActive = billingItems?.some(
    item => item.serviceCode === productSkuIds.starter.primary && item.serviceStatus === ServiceStatus.Active
  );
  let isAccessActive = billingItems?.some(
    item => item.serviceCode === productSkuIds.access.primary && item.serviceStatus === ServiceStatus.Active
  );

  let data = products
    ?.flatMap(product => {
      let quantity = 0;
      let priceFromService: number | undefined = undefined;
      let priceFromCampaign: number | undefined = undefined;
      let serviceStatus: ServiceStatus | undefined = undefined;

      if (multiQuantityCategories.includes(product.categoryId)) {
        const boxesFromOccurrences = getBoxFromOccurrences(boxes, product);

        // If this box is present in occurrence add it as individual box product
        if (boxesFromOccurrences && boxesFromOccurrences.length) {
          return boxesFromOccurrences.map((box, index) => {
            const details = getBoxDetailsFromBilling(
              product,
              [box.occurrenceNumber],
              billing?.services,
              cableCampaigns
            );

            // Multiroom charge of the first box is returned as default.
            // Since its free, when don't need to set quantityBought to 1 to simplify
            // calculations and running total UI.
            let isFirstMultiroom = productSkuIds.multiroom.primary === product.sku && index === 0;
            if (isFirstMultiroom) {
              return product;
            }

            return {
              ...product,
              quantityBought: box.quantity,
              quantityInCart: box.quantity,
              occurrenceNumber: box.occurrenceNumber,
              serviceStatus: ServiceStatus.Active,
              inPromotion: details.inPromotion,
              priceBilled: details.boxPrice,
            } as T_Product;
          });
        }

        return product;
      } else if (!isBroadcastTier) {
        // Matches the billing items serviceCode the konakart product skus.
        // Maps customer 10 products to sky products via stripping the the prefix '11'.
        let foundService = billingItems?.find(
          e => !!product.sku && e.serviceCode.replace(/^(11)/, '').includes(product.sku)
        );
        if (foundService) {
          serviceStatus = foundService.serviceStatus;
          quantity = foundService.quantity;
          // if CSR removes product and sets quantity to 0, the rate.afterTax field will be 0 as person pays nothing
          // but this is not the price from the service for the user
          if (quantity > 0) {
            priceFromService = parseFloat(foundService.rate.afterTax) / 100;

            // assume occurrences#1 has all campaign promotion info -- wrong assumption for dart
            // if the service is found and we are parsing active campaigns, assumption is that the quantity of a campaign product
            // will match what 'quantity' is.
            const activeCampaignOccurrences = uniq(
              cableCampaigns.flatMap(c =>
                c.containedServices.CableServiceOccurrence.map(c => String(c.occurrenceNumber))
              )
            );

            priceFromCampaign = getPriceFromCampaign(
              product,
              cableCampaigns,
              activeCampaignOccurrences,
              CouponTypes.Cable
            );
          }
        }

        // paperless billing check to add paperless billing product
        if (product.sku === productSkuIds.paperBilling.primary) {
          quantity = billing && billing.paperLessBilling ? 0 : 1;
        }

        let priceBilled = priceFromCampaign ?? priceFromService ?? product.priceIncTax;

        let result = {
          ...product,
          quantityBought: quantity,
          quantityInCart: quantity,
          inPromotion: priceFromCampaign !== undefined,
          isPhantom: foundService && foundService.serviceCode.endsWith('P'),
          serviceStatus,
          priceBilled,
        };

        return result;
      }
    })
    .filter(isNonNil)
    .sort((a, b) => {
      return (b.rating ?? 0) - (a.rating ?? 0);
    });

  return {
    data,
    occurrenceNotFound,
    isBroadcastTier,
    isStarterActive,
    isAccessActive,
    hasPendingBox,
  };
};

/**
 * Method will update the products quantities to match the quantities seen in the given billingItems
 *
 * @param products - An Array of products which will have there quantities updated to match whats seen in the given billing items
 * @param billingDetails - An array of service details which quantities need to be replicated for the products
 * @param categoryIds - An array of category Ids to identify which products will be potentially updated e.g. [45,48] aka 45 = broadband, 48 = broadbandDevices
 */
export const updateProductsToMatchBillingServiceDetailsData = (
  products: T_Product[] | undefined,
  billingDetails: ServiceDetail[] | undefined,
  categoryIds: number[],
  dataCampaign: BillingCampaign[] | undefined
): void => {
  // Filter out billing items that have a quantity of 0
  const filteredBillingItems = billingDetails?.filter(billingDetail => billingDetail.quantity > 0);
  // Filter products to include only the ones that have the given category Ids e.g BroadbandDevices = 48
  const productsToBeUpdated = products?.filter(product => categoryIds.includes(product.categoryId));
  const broadbandStarterDiscount = billingDetails?.find(
    billingDetail => billingDetail.serviceCode === productSkuIds.broadbandStarterDiscountOneGig.primary
  );
  const broadbandStarterDiscountSlowSpeed = billingDetails?.find(
    billingDetail => billingDetail.serviceCode === productSkuIds.broadbandStarterDiscountHundredMeg.primary
  );

  // Loop through both the billing items and to be updated products
  filteredBillingItems?.forEach(billingDetail => {
    productsToBeUpdated?.forEach(product => {
      let priceFromService: number | undefined = undefined;
      let priceFromCampaign: number | undefined = undefined;
      let hasPriceHoldCampaign = false;
      // Break down product skus e.g. '00000, 11111, 22222' -> [00000,11111,22222]
      // If one of the products sku codes is the same as the items service code
      // AND
      // The products categoryId is the same as one of the given categoryIds
      // THEN
      // Update the product quantity to match the items quantity
      priceFromService = parseFloat(billingDetail.rate.afterTax) / 100;

      // Note: If you find this in the future I am truly sorry. This is horrible.
      // Discount the active starter package for 1GB broadband in billing if found (150BB)
      if (
        broadbandStarterDiscount &&
        (product.sku === productSkuIds.broadbandLightningFastWiFi.primary ||
          product.sku === productSkuIds.broadbandLightningFastWiFiBoost.primary)
      ) {
        const priceStarterDiscount = parseFloat(broadbandStarterDiscount.rate.afterTax) / 100;
        priceFromService += priceStarterDiscount;
      }

      // Discount the active starter package for 100MB broadband in billing if found (151BB)
      if (
        broadbandStarterDiscountSlowSpeed &&
        (product.sku === productSkuIds.broadbandWifi100.primary ||
          product.sku === productSkuIds.broadbandWifi100Boost.primary)
      ) {
        const priceStarterDiscount = parseFloat(broadbandStarterDiscountSlowSpeed.rate.afterTax) / 100;
        priceFromService += priceStarterDiscount;
      }

      // Campaign Logic
      if (dataCampaign) {
        // Get Occurrence number under the assumption of one product will be within ONE and only ONE active campaign
        let occurrenceNumber: string | undefined;
        dataCampaign.forEach(campaign => {
          occurrenceNumber = campaign.containedServices.DataServiceOccurrence.find(dataServiceOccurrence =>
            dataServiceOccurrence.serviceDetails.find(
              serviceDetail => serviceDetail.serviceCode === billingDetail.serviceCode
            )
          )?.occurrenceNumber?.toString();
          // If product is found in Campaign discount, get the price.
          if (occurrenceNumber) {
            priceFromCampaign =
              dataCampaign && getPriceFromCampaign(product, dataCampaign, [occurrenceNumber], CouponTypes.Broadband);
            if (priceHoldCampaignCodes.includes(campaign.promotionCode)) {
              hasPriceHoldCampaign = true;
            }
          }
        });
      }
      if (
        product.sku
          .split(',')
          .map(s => s.trim())
          .includes(billingDetail.serviceCode) &&
        categoryIds.includes(product.categoryId)
      ) {
        if (product.sku === billingDetail.serviceCode) {
          product.quantityBought =
            billingDetails?.filter(x => x.serviceCode.includes(billingDetail.serviceCode)).length || 0;
        } else {
          product.quantityBought = billingDetail.quantity;
        }
        product.quantityInCart = billingDetail.quantity;
        product.priceBilled = priceFromCampaign ?? priceFromService ?? product.priceIncTax;
        product.inPromotion = priceFromCampaign !== undefined;
        product.broadbandPriceHold = hasPriceHoldCampaign;
        product.serviceStatus = billingDetail.serviceStatus;
      }
    });
  });
};

/**
 * Method will update the products quantities to match the quantities seen in the given billingItems along with the price
 *
 * @param products - An Array of products which will have there quantities updated to match whats seen in the given billing items
 * @param billingDetails - An array of service details which quantities and prices need to be replicated for the products
 */
export const updateProductsToMatchBillingServiceDetailsVoice = (
  products: T_Product[] | undefined,
  billingDetails: ServiceDetail[] | undefined,
  voiceCampaign: BillingCampaign[] | undefined
): void => {
  // Update voice related products based on price + quantity
  products?.forEach((product, index) => {
    if (voiceCategories.includes(product.categoryId)) {
      let quantity = 0;
      let priceFromService: number | undefined = undefined;
      let priceFromCampaign: number | undefined = undefined;
      let serviceStatus: ServiceStatus | undefined = undefined;
      // normal product, normally should be 1 or 0.
      const foundService = billingDetails?.find(e => e.serviceCode === product.sku);
      if (foundService) {
        quantity = foundService.quantity;
        serviceStatus = foundService.serviceStatus;
        // if CSR removes product and sets quantity to 0, the rate.afterTax field will be 0 as person pays nothing
        // but this is not the price from the service for the user
        if (quantity > 0) {
          priceFromService = parseFloat(foundService.rate.afterTax) / 100;

          // We will get only 1 occurrence for Voice with all the subscribed services
          priceFromCampaign = voiceCampaign && getPriceFromCampaign(product, voiceCampaign, ['1'], CouponTypes.Voice);
        }
      }

      const priceBilled = priceFromCampaign ?? priceFromService ?? product.priceIncTax;
      products[index] = {
        ...products[index],
        quantityBought: quantity ? quantity : product.quantityBought,
        quantityInCart: quantity ? quantity : product.quantityInCart,
        priceBilled,
        serviceStatus,
        inPromotion: priceFromCampaign !== undefined,
      };
    }
  });
};

/**
 * Checks if the product sku is a mesh package
 *
 * @param productSku - string sku to check against
 */
export const checkProductIsBroadbandMeshPackage = (productSku: string): boolean => {
  if (
    productSku === productSkuIds.broadbandWifi100Boost.primary ||
    productSku === productSkuIds.broadbandLightningFastWiFiBoost.primary
  ) {
    return true;
  }
  return false;
};

/**
 * Checks the given billing items to the given service code to see if there is an item that match the given service code and its quantity is greater than 0
 *
 * @param billingItems - An array of billing items which quantities need to be replicated for the products
 * @param serviceCodes - An array of service codes to see if they are active on the billing items
 */
export const itemsExistOnBilling = (billingItems: ServiceDetail[] | undefined, serviceCodes: string[]): boolean => {
  const service =
    billingItems?.filter(item => item.quantity > 0).filter(item => serviceCodes.includes(item.serviceCode)) || [];

  return service.length > 0;
};

/**
 * Check whether existing campaigns from the promotion code
 *
 * @param campaigns list of campaigns
 * @param promotionCodes list of promotion codes
 * @returns
 */
export const campaignCodesExistOnBilling = (
  campaigns: BillingCampaign[] | undefined,
  promotionCodes: string[]
): boolean => {
  const service = campaigns?.filter(item => promotionCodes.includes(item.promotionCode)) || [];

  return service.length > 0;
};

/**
 * Handles Data packages based on billing
 * @param activeServiceDetails
 * @param pendingServiceDetails
 */
export const resolveBroadbandDataPackages = (
  activeServiceDetails: ServiceDetail[] | undefined,
  pendingServiceDetails: ServiceDetail[] | undefined,
  campaigns: BillingCampaign[] | undefined
) => {
  // Currently 4 different base packages for broadband
  // Current tech debt to categorize these properly in konakart, see WEBDXP-3157
  const lightningFastBroadbandSku = productSkuIds.broadbandLightningFastWiFi.primary;
  const lightningFastBroadbandBoostSku = productSkuIds.broadbandLightningFastWiFiBoost.primary;
  const wifi100BroadbandSku = productSkuIds.broadbandWifi100.primary;
  const wifi100BroadbandBoostSku = productSkuIds.broadbandWifi100Boost.primary;
  const summerSetIdentifier = productSkuIds.summerSetIdentifier.primary;
  const summerSetEssn = productSkuIds.broadbandEssnFibre.primary;
  const summerSetEssnBoo = productSkuIds.broadbandEssnFibBoost.primary;
  const hasSummerSetCampaign = campaigns?.some(campaign => summerSetCampaignCode.includes(campaign?.promotionCode));
  const allPackageSkus = [
    lightningFastBroadbandSku,
    lightningFastBroadbandBoostSku,
    wifi100BroadbandSku,
    wifi100BroadbandBoostSku,
    summerSetEssn,
    summerSetEssnBoo,
  ];
  const meshPackagesSkus = [lightningFastBroadbandBoostSku, wifi100BroadbandBoostSku];

  const hasActiveBroadbandPackage = itemsExistOnBilling(activeServiceDetails, allPackageSkus);
  const hasSummerSet = itemsExistOnBilling(activeServiceDetails, [summerSetIdentifier]) && hasSummerSetCampaign;
  const hasPendingBroadbandPackage = itemsExistOnBilling(pendingServiceDetails, allPackageSkus);
  const hasActiveOrPendingBroadbandPackage = hasActiveBroadbandPackage || hasPendingBroadbandPackage;

  // Mesh, also known as Sky Boost, is active
  const hasActiveMeshBroadbandPackage = itemsExistOnBilling(activeServiceDetails, meshPackagesSkus);

  // Mesh, also known as Sky Boost, is pending
  const hasPendingMeshBroadbandPackage = itemsExistOnBilling(pendingServiceDetails, meshPackagesSkus);

  const hasActiveOrPendingMeshBroadbandPackage = hasActiveMeshBroadbandPackage || hasPendingMeshBroadbandPackage;

  return {
    hasActiveMeshBroadbandPackage,
    hasPendingMeshBroadbandPackage,
    hasActiveOrPendingMeshBroadbandPackage,
    hasActiveBroadbandPackage,
    hasPendingBroadbandPackage,
    hasActiveOrPendingBroadbandPackage,
    hasSummerSet,
  };
};

/**
 * Handles voucher eligibility based on billing
 * @param activeServiceDetails
 */
export const resolveVoucherEligibility = (
  activeServiceDetails: ServiceDetail[] | undefined,
  voucherEligibilityCode: string[]
) => {
  return itemsExistOnBilling(activeServiceDetails, voucherEligibilityCode);
};

/**
 * Handles Broadband Device related packages
 * @param activeServiceDetails
 * @param pendingServiceDetails
 */
export const resolveBroadbandDevicePackages = (
  activeServiceDetails: ServiceDetail[] | undefined,
  pendingServiceDetails: ServiceDetail[] | undefined
) => {
  // Router
  const hasActiveRouterDevice = itemsExistOnBilling(activeServiceDetails, [productSkuIds.skyRouter.primary]);
  // Mesh Device
  const hasActiveMeshDevice = itemsExistOnBilling(activeServiceDetails, [productSkuIds.meshDevice.primary]);
  const hasPendingMeshDevice = itemsExistOnBilling(pendingServiceDetails, [productSkuIds.meshDevice.primary]);

  return {
    hasActiveRouterDevice,
    hasActiveMeshDevice,
    hasPendingMeshDevice,
  };
};

/**
 * Handles Broadband Service related packages
 * @param activeServiceDetails
 * @param pendingServiceDetails
 */
export const resolveBroadbandServicePackages = (
  activeServiceDetails: ServiceDetail[] | undefined,
  pendingServiceDetails: ServiceDetail[] | undefined
) => {
  // Mesh Device
  const hasActiveStaticIp = itemsExistOnBilling(activeServiceDetails, [productSkuIds.broadbandStaticIP.primary]);
  const hasPendingStaticIp = itemsExistOnBilling(pendingServiceDetails, [productSkuIds.broadbandStaticIP.primary]);

  return {
    hasActiveStaticIp,
    hasPendingStaticIp,
  };
};

/**
 * Handles Voice related packages
 * @param activeServiceDetails
 * @param pendingServiceDetails
 */
export const resolveVoicePackages = (
  activeServiceDetails: ServiceDetail[] | undefined,
  pendingServiceDetails: ServiceDetail[] | undefined
) => {
  const hasPendingVoicePackage = itemsExistOnBilling(pendingServiceDetails, [productSkuIds.voice.primary]);
  const hasActiveVoicePackage = itemsExistOnBilling(activeServiceDetails, [
    productSkuIds.voice.primary,
    productSkuIds.voiceSummerSet.primary,
  ]);

  return {
    hasActiveVoicePackage,
    hasPendingVoicePackage,
  };
};

/**
 * Handles Vodafone and Igloo customers
 * @param activeVodafoneCustomers
 * @param activeIglooCustomers
 */
export const resolveVodafoneIglooPackages = (
  activeServiceDetails: ServiceDetail[] | undefined,
  campaigns: BillingCampaign[] | undefined
) => {
  const isActiveVodafoneCustomers = itemsExistOnBilling(activeServiceDetails, vodafoneCodes);
  const isActiveIglooCustomers = campaignCodesExistOnBilling(campaigns, iglooCodes);
  const isActiveDigiRentalCustomer = itemsExistOnBilling(activeServiceDetails, digiRentalCodes);

  return {
    isActiveVodafoneCustomers,
    isActiveIglooCustomers,
    isActiveDigiRentalCustomer,
  };
};

export const isUserType = (userGroupTypes: UserGroupType[], groupType: UserGroupType) =>
  userGroupTypes.some(ugt => ugt === groupType);

/**
 * Returns the product set based on the customer's billing information.
 *
 * 001 for backbook
 * 002 for frontbook
 */
export const getCustomerProductSet = (billing: T_Billing) => {
  const productSet = billing.services?.CableServiceOccurrence?.filter(
    s => s.status === ServiceStatus.Active || s.status === ServiceStatus.PendingInstall
  )
    .flatMap(s => s.serviceDetails)
    .filter(s => s.serviceStatus === ServiceStatus.Active)
    .find(s => s.serviceCode === catalogueTypeSKU.frontbook || s.serviceCode === catalogueTypeSKU.backbook);

  return productSet?.serviceCode;
};
