import React from 'react';

interface BoldedTitleProps {
  title: string;
  query: string;
}

export const BoldedTitle = ({ title, query }: BoldedTitleProps) => {
  const re = new RegExp(`\\b(${query})\\b`, 'i');
  const texts = title.split(re).filter(Boolean);

  return (
    <p className="pl-2 pr-4 w-3/4">
      {texts.map((text, index) => (
        <span key={index}>{text.toLowerCase() === query.toLowerCase() ? <b>{text}</b> : text}</span>
      ))}
    </p>
  );
};
